<div mat-dialog-content>
    
    <div class="dialog-content">
        <p>
            <strong>Atenção</strong>
        </p>
        <p>Tem certeza que deseja cancelar essa reserva?</p>
    </div>

    <div mat-dialog-actions class="actions-container">  
        <button mat-button (click)="onNoClick()">fechar</button>
        <button mat-button class="cancel-reserve"cdkFocusInitial (click)="handleCancellation()">
            <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status"></div>
            <p class="cancel-text">Cancelar reserva</p>
        </button>
    </div>
</div>
  