<section class="mt-5 mb-5 precheckin">
    <my-portal-highlighter [module]="{title: 'Pré-checkin'}"></my-portal-highlighter>
    <form name="preCheckinForm" [formGroup]="preCheckinForm" (ngSubmit)="submit()">
        <div class="form-container">
            <h6 *ngIf="typeSearch >= 6">Estabelecimento</h6>
            <mat-divider *ngIf="typeSearch >= 6"></mat-divider>

            <div class="row mt-4 mb-2" *ngIf="typeSearch >= 6">
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <mat-form-field appearance="outline">
                        <mat-label>Estabelecimento</mat-label>
                        <mat-select required formControlName="pessoa_id">
                            <mat-option *ngFor="let hotel of hotels" [value]="hotel?.id">{{ hotel?.name }}</mat-option>
                        </mat-select>
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <h6>Dados da reserva</h6>
            <mat-divider></mat-divider>

            <div class="row mt-4 mb-2">
                <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Reserva</mat-label>
                        <input matInput placeholder="Informe o número da reserva" required formControlName="reserve">
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Local da reserva</mat-label>
                        <input matInput placeholder="Ex: Site do hotel, Booking.com..." required
                               formControlName="origin">
                        <mat-error>Este campo é obrigatório</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                    <!--                    <mat-form-field appearance="outline">-->
                    <!--                        <mat-label>Hotel</mat-label>-->
                    <!--                        <mat-select required formControlName="hotel_id">-->
                    <!--                            <mat-option value="734">Hotel 1</mat-option>-->
                    <!--                            <mat-option value="977">Hotel 13</mat-option>-->
                    <!--                        </mat-select>-->
                    <!--                        <mat-error>Este campo é obrigatório</mat-error>-->
                    <!--                    </mat-form-field>-->
                    <mat-form-field appearance="outline">
                        <mat-label>Check-in/out *</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Check-in" required [formControlName]="'checkin'">
                            <input matEndDate placeholder="Check-out" required [formControlName]="'checkout'">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>

            <div formGroupName="guest">
                <h6>Dados pessoais</h6>
                <mat-divider></mat-divider>

                <div class="row mt-4 mb-2">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome</mat-label>
                            <input matInput placeholder="José" required formControlName="name">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Sexo</mat-label>
                            <mat-select required [formControlName]="'genre'"
                            >
                                <mat-option value="M">Masculino</mat-option>
                                <mat-option value="F">Feminino</mat-option>
                            </mat-select>
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <!--                    (change)="checkDates('data_nascimento')"-->
                        <mat-form-field appearance="outline">
                            <mat-label>Data de nascimento</mat-label>
                            <input matInput placeholder="00/00/0000" [dropSpecialCharacters]="false"
                                   [mask]="'00/00/0000'" [validation]="true" required formControlName="birth_date">
                            <mat-error *ngIf="preCheckinForm.controls.guest.controls.birth_date.errors?.required">Este campo é obrigatório</mat-error>
                            <mat-error *ngIf="!preCheckinForm.controls.guest.controls.birth_date.errors?.required && preCheckinForm.controls.guest.controls.birth_date.errors?.invalidDate">Insira uma data válida</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>E-mail</mat-label>
                            <input matInput placeholder="seunome@email.com" required formControlName="email">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Telefone</mat-label>
                            <input matInput ng2TelInput [ng2TelInputOptions]="{initialCountry: 'br', preferredCountries: ['BR', 'UY', 'AR', 'PY', 'BO', 'PE', 'CO', 'VE', 'GY', 'SR', 'GF']}"
                                (countryChange)="onCountryChange($event, 'phone')"
                                [placeholder]="phone.sigla == 'br' ? '(00) 0 0000-0000' :
                                'Telefone'" [mask]="phone.sigla == 'br' ? '(00) 0000-0000 || (00) 0 0000-0000' : '0000000000000000'"
                                formControlName="phone_number" [validation]="phone.sigla == 'br'" required >
                            <mat-error >Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Celular</mat-label>
                            <input matInput ng2TelInput [ng2TelInputOptions]="{initialCountry: 'br', preferredCountries: ['BR', 'UY', 'AR', 'PY', 'BO', 'PE', 'CO', 'VE', 'GY', 'SR', 'GF']}"
                                (countryChange)="onCountryChange($event, 'cell')"
                                [placeholder]="cell.sigla == 'br' ? '(00) 0 0000-0000' :
                                'Celular'" [mask]="cell.sigla == 'br' ? '(00) 0000-0000 || (00) 0 0000-0000' : '0000000000000000'"
                                formControlName="cell" [validation]="cell.sigla == 'br'" required >
                            <mat-error >Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Profissão</mat-label>
                            <input matInput placeholder="Profissão" required formControlName="job">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nacionalidade</mat-label>
                            <input matInput placeholder="Nacionalidade" required formControlName="nationality">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Quantidade de hóspede(s)</mat-label>
                            <input matInput required placeholder="Quantidade de hóspede(s)"
                                   formControlName="pax">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <h6>Documentos</h6>
                <mat-divider></mat-divider>

                <div class="row mt-4 mb-2" formGroupName="documentation">

                    <div class="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Seu Documento é de Origem Estrangeira?</mat-label>
                            <mat-select required [formControlName]="'foreign'" (selectionChange)="selectTypeTourist()">
                                <mat-option value="S">Sim</mat-option>
                                <mat-option value="N">Não</mat-option>
                            </mat-select>
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF (Brazilian Document)</mat-label>
                            <input matInput placeholder="000.000.000-00"
                                   [mask]="'000.000.000-00'"
                                   [formControlName]="'cpf'"
                                   required>
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-2" formGroupName="documentation">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Tipo de documento</mat-label>
                            <mat-select required [formControlName]="'type'" (selectionChange)="selectTypeDocument()">
                                <mat-option [value]="1" *ngIf="preCheckinForm.value.guest.documentation.foreign === 'N'">RG
                                </mat-option>
                                <mat-option [value]="3">Passaporte</mat-option>
                                <mat-option [value]="2" *ngIf="preCheckinForm.value.guest.documentation.foreign === 'S'">Outro documento</mat-option>
                            </mat-select>
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Documento</mat-label>
                            <input matInput placeholder="Documento" (focus)="focusMaskRg()" (blur)="maskRg()"
                                   [mask]="outputMaskRg"
                                   required formControlName="number">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Orgão emissor</mat-label>
                            <input matInput placeholder="Orgão emissor"
                                   formControlName="issuing_agency">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <h6>Residência permanente</h6>
                <mat-divider></mat-divider>

                <div class="row mt-4 mb-2" formGroupName="address">
                    <!--                -->
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>CEP</mat-label>
                            <input matInput placeholder="00000-000" [mask]="'00000-000'" required
                                   formControlName="zip_code"
                                   (input)="updateCep(preCheckinForm.value.guest.address.zip_code)">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Endereço</mat-label>
                            <input matInput placeholder="Av. Paulista" required
                                   formControlName="public_place">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Bairro</mat-label>
                            <input matInput
                                   placeholder="Centro" required formControlName="district">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput placeholder="São Paulo"
                                   required formControlName="city">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-2" formGroupName="address">
                    <div class="col-6 col-md-6 col-sm-6 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Número</mat-label>
                            <input matInput placeholder="999" formControlName="number">
                        </mat-form-field>
                    </div>
                    <div class="col-6 col-md-6 col-sm-6 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput placeholder="Casa, Apto 401"
                                   formControlName="complement">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput placeholder="Rio de Janeiro"
                                   formControlName="state">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-3 col-xl-3">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput placeholder="Rio de Janeiro"
                                   formControlName="county">
                        </mat-form-field>
                    </div>
                </div>

                <h6>Última procedência</h6>
                <mat-divider></mat-divider>

                <div class="row mt-4 mb-2" formGroupName="provenance">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput placeholder="Cidade" required
                                   formControlName="city">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput placeholder="Estado" required
                                   formControlName="state">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput
                                   placeholder="País" required formControlName="county">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <h6>Próximo destino</h6>
                <mat-divider></mat-divider>

                <div class="row mt-4 mb-2" formGroupName="destination">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Cidade</mat-label>
                            <input matInput placeholder="Cidade" required
                                   formControlName="city">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Estado</mat-label>
                            <input matInput placeholder="Estado" required
                                   formControlName="state">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-4 col-xl-4">
                        <mat-form-field appearance="outline">
                            <mat-label>País</mat-label>
                            <input matInput
                                   placeholder="País" required formControlName="county">
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <mat-divider></mat-divider>

                <div class="row mt-4 mb-2" formGroupName="destination">
                    <div class="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Motivo da viagem</mat-label>
                            <mat-select required [formControlName]="'reason_travel'"
                            >
                                <mat-option value="1">Lazer</mat-option>
                                <mat-option value="2">Negócios</mat-option>
                                <mat-option value="3">Congresso</mat-option>
                                <mat-option value="4">Parentes</mat-option>
                                <mat-option value="5">Estudos</mat-option>
                                <mat-option value="6">Religião</mat-option>
                                <mat-option value="7">Saúde</mat-option>
                                <mat-option value="8">Compras</mat-option>
                                <mat-option value="9">Outro</mat-option>
                            </mat-select>
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Meio de transporte</mat-label>
                            <mat-select required [formControlName]="'transport'"
                            >
                                <mat-option value="1">Avião</mat-option>
                                <mat-option value="2">Automóvel</mat-option>
                                <mat-option value="3">Ônibus</mat-option>
                                <mat-option value="4">Moto</mat-option>
                                <mat-option value="5">Navio</mat-option>
                                <mat-option value="6">Trem</mat-option>
                                <mat-option value="7">Outro</mat-option>
                            </mat-select>
                            <mat-error>Este campo é obrigatório</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <my-portal-privacy-policy (policyEvent)="privacyPolicy($event)" [resetPolicyCheckbox]="resetPolicyCheckbox"></my-portal-privacy-policy>
                    <my-portal-recaptcha (recaptchaEvent)="recaptcha($event)"></my-portal-recaptcha>
                </div>
            </div>

            <div class="button-container mb-2">
                <button [disabled]="(!preCheckinForm.valid || !disabledButton || !disabledRecaptcha || loading)"
                        type="submit">
                    <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status"></div>
                    <span *ngIf="!loading">Enviar pré check-in</span>
                </button>
            </div>
        </div>
    </form>
</section>
