<section *ngIf="about" class="mt-5 mb-3 about">
    <div *ngIf="moduleData" class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 d-flex justify-content-between">
            <my-portal-highlighter [module]="portal ? {title: 'Quem somos'} : moduleData"></my-portal-highlighter>
        </div>
    </div>

    <div class="box-info" *ngIf="!moduleData">
        <div class="hotel-info" [ngClass]="{'session_marker': session_markers === 2}">
            <h4>{{about?.data_establishment?.name}}</h4>
            <div class="info">
                <div class="star" *ngIf="!info?.hide_stars">
                    <span translate="no" class="material-icons-outlined" *ngFor="let st of [0,1,2,3,4]; let ind = index"
                        [ngClass]="{'active': (ind+1) <= about?.data_establishment?.stars}">star</span>
                </div>
                <!--            <div>222 avaliações</div>-->
                <div class="local" *ngIf="!info?.hide_address">
                    <span translate="no" class="material-icons-outlined">room</span>
                    <span>{{about?.data_establishment?.city + ', ' + about?.data_establishment?.state}}</span>
                </div>

                <!-- <div *ngIf="(info?.checkin || info?.checkout) && !showLogo" class="d-flex schedule notranslate mr-2">
                    <div class="schedule-icon mr-2">
                        <svg id="Camada_1" stroke="currentColor" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke-miterlimit:10;}</style></defs><title>ico-relogio</title><circle class="cls-1" cx="12" cy="12" r="11"/><polyline class="cls-1" points="17.79 12.58 11.42 12.58 11.42 4.47"/></svg>
                    </div>

                    <div class="d-flex align-items-center">
                        <span>Check-in: {{info.checkin}} | </span>
                        <span class="ml-1"> Check-out: {{info.checkout}}</span>
                    </div>
                </div> -->
            </div>
        </div>

        <figure *ngIf="about?.logo  && showLogo" class="about-figure">
            <img class="about-logo" [src]="imgServer + 'width=' + 205 + imgServerUrlEnd + about?.logo?.image" width="205" height="auto" alt="logo">
        </figure>
<!--        rates ||-->
        <!-- <my-portal-rate-comparator *ngIf="rates" [rates]="rates"></my-portal-rate-comparator> -->
    </div>
    <div class="box" [ngClass]="{'search' : !moduleData}">

        <!-- <my-portal-benefits *ngIf="!portal" [benefits]="about['carousel']" [search]="moduleData"></my-portal-benefits> -->
        <div class="row no-gutters border">
            <div [ngClass]="{
            'col-sm-12 col-md-6 col-lg-6 col-xl-6': !search,
            'col-sm-12 col-md-4 col-lg-4 col-xl-4': search
            }">
                <swiper [config]="config">
                    <div class="swiper-wrapper">
                        <div *ngFor="let image of about?.data_establishment?.url" class="benefit swiper-slide">
                            <figure [ngClass]="{'loading': load }">
                                <img class="img-fluid" src="{{imgServer + 'width=' + 640 + imgServerUrlEnd + image}}" width="640" height="auto" alt="imagem">
                            </figure>
                        </div>
                    </div>
                    <div class="swiper-pagination pagination-accommodation pagination-about"></div>
                </swiper>
            </div>
            <div [ngClass]="{
            'col-sm-12 col-md-6 col-lg-6 col-xl-6 p-md-4 p-lg-4 p-xl-4': !search,
            'col-sm-12 col-md-8 col-lg-8 col-xl-8 p-md-4 p-lg-4 p-xl-4': search
            }" class="box-about">
                <h6 class="hotel-title notranslate" [ngClass]="{'loading': load }">{{!moduleData ? 'Quem somos' :
                    about?.data_establishment?.name}}</h6>
                <div [ngClass]="{'my-3': load }">
                    <p [innerHTML]="about.data_establishment.description | sanitizeHtml"
                        [ngClass]="{'loading': load,
                        'all-description': showText}">
                    </p>
                    <a *ngIf="!hiddenShowMore && about?.data_establishment.description" (click)="showText = !showText">{{showText ? 'ver menos' : 'ver mais' }}</a>
                </div>
                <h6 *ngIf="!portal && about.data_establishment.amenities.length" class="mb-1" [ngClass]="{'loading': load }">Comodidades</h6>
                <my-portal-resources *ngIf="!portal && about.data_establishment.amenities.length" [resources]="about?.data_establishment?.amenities"
                    [moreItems]="true" [mb]="true"></my-portal-resources>
                <div *ngIf="(info?.checkin || info?.checkout)" class=" mt-2 d-flex schedule notranslate mr-2">
                    <div class="mt-1 schedule-icon mr-2">
                        <svg id="Camada_1" stroke="currentColor" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke-miterlimit:10;}</style></defs><title>ico-relogio</title><circle class="cls-1" cx="12" cy="12" r="11"/><polyline class="cls-1" points="17.79 12.58 11.42 12.58 11.42 4.47"/></svg>
                    </div>

                    <div class="mt-2 d-flex checkin-checkout-mobile align-items-center">
                        <h6>Check-in: {{info.checkin}} | </h6>
                        <h6 class="ml-1"> Check-out: {{info.checkout}}</h6>
                    </div>
                </div>
            </div>
        </div>

        <my-portal-brands *ngIf="portal && about?.logo.length" [brands]="about?.logo"></my-portal-brands>
    </div>
</section>
