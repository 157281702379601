<div class="container-toolbar" [ngClass]="{
    'model-3': dataHeader?.header?.type > 1,
    'only-motor': dataHeader?.only_motor,
    'toolbar-position-absolute': position,
    'model-1': dataHeader?.header?.type == 1
}">
    <mat-toolbar class="pt-1 pb-1">
        <mat-toolbar-row>
            <div class="container-fluid">
                <div class="row">
                    <div class="col d-flex align-items-center justify-content-between">
                        <div class="d-flex justify-content-between w-100">
                            <button *ngIf="!dataHeader?.hide_menu_mobile"
                                    (click)="toggleRightSidenav()"
                                    class="d-block d-md-none d-lg-none d-xl-none"
                                    mat-icon-button
                                    [attr.aria-label]="'Menu'">
                                <mat-icon>menu</mat-icon>
                            </button>

                            <div *ngIf="dataHeader?.hide_menu_mobile">
                                <mat-icon class="d-none">menu</mat-icon>
                            </div>
                            <a [routerLink]="'/'">
                                <figure>
                                    <img
                                        class="img-fluid"
                                        [ngClass]="{'resize': hiddenSlide, 'logo-full': dataHeader?.header?.type_menu === 2}"
                                        src="{{ dataHeader?.logo ? imgServer + 'width=' + 207 + imgServerUrlMaxQuality + dataHeader?.logo : 'https://images.focomultimidia.com/curl/motor_reserva/images/logo.png' }}"
                                        alt="Logo">
                                </figure>
                            </a>
                        </div>

                        <div class="d-none d-md-flex d-lg-flex d-xl-flex justify-content-end">
                            <div *ngIf="!dataHeader.only_motor">
                                <div class="dropdown" *ngFor="let menu of dataHeader?.menus">

                                    <a [routerLink]="menu.url" *ngIf="menu.target === '_self'">
                                        <button
                                            mat-button>
                                            {{ menu.title }}
                                            <mat-icon *ngIf="menu.submenu.length > 0">arrow_drop_down</mat-icon>
                                        </button>
                                    </a>

                                    <a [href]="menu.url" [target]="menu.target" *ngIf="menu.target === '_blank'">
                                        <button
                                            mat-button>
                                            {{ menu.title }}
                                            <mat-icon *ngIf="menu.submenu.length > 0">arrow_drop_down</mat-icon>
                                        </button>
                                    </a>

                                    <div class="dropdown-content" *ngIf="menu.submenu.length > 0">
                                        <a *ngFor="let sub of menu.submenu" [href]="sub.url"
                                           [target]="sub.target">{{sub.title}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="restricted"
                                 *ngIf="(!dataHeader.only_motor && hiddenSlide) && dataHeader?.menus.length < 7">
                                <button *ngIf="dataHeader.restrited_area && dataHeader.type !== 6" mat-button
                                        (click)="openDialogLoginType(userLogged)">
                                    Minha conta
                                </button>

                                <button *ngIf="!dataHeader.hide_contact" (click)="handleRouteClick('contato')" mat-button>
                                    Contato
                                </button>

                                <div class="my-searches" *ngIf="!dataHeader?.hide_my_search">
                                    <button mat-button class="trigger" (click)="handleShowSearches()">
                                        Minhas buscas
                                    </button>

                                    <my-portal-my-searches *ngIf="showSearches"></my-portal-my-searches>

                                    <!-- <div class="back-drop" *ngIf="showSearches"  (click)="handleShowSearches()"></div> -->
                                </div>
                            </div>

                            <div class="restricted"
                                 *ngIf="(!dataHeader.only_motor && hiddenSlide) && dataHeader?.menus.length >= 7">
                                <div class="dropdown dropdown-navbar" *ngIf="!dataHeader?.hide_my_search || !dataHeader.hide_contact || dataHeader.restrited_area">
                                    <div class="d-flex">
                                        <a class="pl-2 d-flex justify-content-center align-items-center">
                                            Minha conta
                                            <i class="material-icons-outlined notranslate">expand_more</i>
                                        </a>
                                    </div>
                                    <div class="dropdown-content">
                                        <button *ngIf="dataHeader.restrited_area && dataHeader.type !== 6" mat-button
                                                (click)="openDialogLoginType(userLogged)">
                                            Minha conta
                                        </button>

                                        <button *ngIf="!dataHeader.hide_contact" (click)="handleRouteClick('contato')" mat-button>
                                            Contato
                                        </button>

                                        <div class="my-searches" *ngIf="!dataHeader?.hide_my_search">
                                            <button mat-button class="trigger" (click)="handleShowSearches()"
                                                    class="w-100">
                                                Minhas buscas
                                            </button>

                                            <my-portal-my-searches
                                                [veritifactionVert]="this.dataHeader?.menus.length >= 7"
                                                *ngIf="showSearches"></my-portal-my-searches>

                                            <!-- <div class="back-drop" *ngIf="showSearches"  (click)="handleShowSearches()"></div> -->
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="restricted" *ngIf="dataHeader.only_motor">
                                <button mat-button>
                                    <a [href]="dataHeader.url_site" target="_blank">
                                        Voltar ao site
                                    </a>
                                </button>
                                <button *ngIf="dataHeader.restrited_area && dataHeader.type !== 6" mat-button
                                        (click)="openDialogLoginType(userLogged)">
                                    Minha conta
                                    <!-- {{ userLogged ? 'Minha conta' : 'Área Restrita' }} -->
                                </button>
                            </div>

                            <div *ngIf="hiddenSlide && dataHeader?.type > 3">
                                <a [routerLink]="'/'" class="ml-2">
                                    <button mat-icon-button [matTooltip]="'Início'" class="home">
                                        <mat-icon>home</mat-icon>
                                    </button>
                                </a>
                            </div>
                            <div class="container-translate d-flex align-items-center" *ngIf="((dataHeader?.translate && !getScreenWidth) || dataHeader?.type !== 3) && hiddenSlide">
                                <my-portal-intern-page-translate [whiteBackground]="true" [dataHeader]="dataHeader"></my-portal-intern-page-translate>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
