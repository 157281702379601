<div [hidden]="hiddenSlider" class="bar-sup d-none d-md-block d-lg-block d-xl-block" [ngClass]="{
    'model-3': dataHeader?.header?.type > 1,
    'colored': dataHeader?.header?.type_menu == 3
 }">
    <!--    'portal': dataHeader?.type == 6-->

    <div class="container-fluid">
        <div class="row">
            
            <div class="col-md-12">
                <div class="inner">
                    <div class="item" *ngIf="!userLogged && dataHeader.restrited_area && dataHeader.type !== 6">
                        <a (click)="openDialog('client')">Acesso Cliente</a>
                    </div>
                    <div class="item" *ngIf="!userLogged && dataHeader.restrited_area && dataHeader.type !== 6">
                        <a (click)="openDialog('corporate')">Acesso Corporativo</a>
                    </div>
                    <div class="item" *ngIf="userLogged">
                        <p>
                            {{userLogged && 'Olá, ' + userData?.name}}
                        </p>
                    </div>
                    <div class="item" *ngIf="userLogged">
                        <a [routerLink]="userData?.type === 'client' ? ['area-restrita', 'cliente'] : ['area-restrita', 'corporativo']">Minha conta</a>
                    </div>
                    <div class="item" *ngIf="!dataHeader.hide_contact">
                        <a [routerLink]="'contato'">Contato</a>
                    </div>
                    <div class="item" *ngIf="!dataHeader?.hide_my_search">
                        <a class="trigger" (click)="handleShowSearches()">Minhas buscas</a>

                        <my-portal-my-searches *ngIf="showSearches"></my-portal-my-searches>

                        <!-- <div class="back-drop" *ngIf="showSearches"  (click)="showSearches = !showSearches"></div> -->
                    </div>
                    <div *ngIf="dataHeader?.translate && !getScreenWidth">
                        <my-portal-translate [dataHeader]="dataHeader"></my-portal-translate>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
