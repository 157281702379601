<div class="guests-wrapper" *ngIf="dataHeader.accommodation_type == 1 || typeAcomodation == 1">
    <div (click)="openModal()"
         class="container-guests" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch}">
        <label *ngIf="(!mobileSearch && !otherForm) || (isMobile && !mobileSearch) || lineSearch">Quantos Hóspedes?</label>
        <div class="form-field">
            <div class="container-form-guests">
                <div class="guests" [ngClass]="{'mobile-search': mobileSearch}">
                    <div class="item notranslate mr-3">
                        <figure>
                            <img alt="" class="svg-icon"
                                 src="assets/images/ico-buscador-hospedes.svg" width="20" height="20">
                        </figure>
                        {{ modelRoom.pax + modelRoom.children }}
                        <span translate="yes" *ngIf="searchType < 6 && (( modelRoom.pax + modelRoom.children) > 1) "
                                class="quantity-guest"
                                [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch, 'portal': squaredSearch}">
                                hóspedes
                        </span>
                        <span translate="yes" *ngIf="searchType < 6 && (( modelRoom.pax + modelRoom.children) <= 1)"
                                class="quantity-guest"
                                [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch, 'portal': squaredSearch}">
                                hóspede
                        </span>
                    </div>
                    <div  *ngIf="!mobileSearch" class="item notranslate ml-3">
                        <figure>
                            <img alt=""
                                 class="svg-icon"
                                 src="assets/images/ico-buscador-cama-casal.svg" width="20" height="20">
                        </figure>
                        {{ modelRoom.accommodation }}
                        <span class="quantity-accomodation">{{modelRoom.accommodation > 1 ? 'acomodações' : 'acomodação'}}</span>
                    </div>
                </div>
                <span translate="no" *ngIf="!mobileSearch" class="material-icons">{{ openConfigSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </div>
        </div>
    </div>
    <div [@.disabled]="!isMobile" @slideUpAndDown *ngIf="openConfigSearch" [ngClass]="{'active': openConfigSearch, 'rounded': roundedSearch, 'squared': squaredSearch}" class="box-guest">
        <span translate="no" class="material-icons arrow">arrow_drop_up</span>
        <div class="stepper">
            <div class="inner-stepper">

                <div class="back-button-container">
                    <button matRipple class="back-button" (click)="handleBackButton()">
                        <span translate="no" class="material-icons-outlined">chevron_left</span>
                    </button>
                    <div class="selected-local">
                        <span>{{ selectedLocalAndDates?.name }}</span>
                        <span class="date" *ngIf="selectedLocalAndDates?.selectedDates">
                            {{ selectedLocalAndDates?.selectedDates?.checkin | date: 'dd MMM' }} à {{ selectedLocalAndDates?.selectedDates?.checkout | date: 'dd MMM' }}
                        </span>

                        <span class="model-room">
                            {{ modelRoom.pax + ' adulto(s)'}}
                            {{ modelRoom.children ? ' - ' + modelRoom.children + ' criança(s)' : ''}}
                        </span>
                    </div>
                </div>

                <ng-template #TempRooms *ngFor="let room of rooms; let key = index"
                             [ngTemplateOutlet]="TempRooms">
                    <div class="stepper-item">
                        <h5 class="client-theme-color">
                            Acomodação {{key + 1}}
                            <span translate="no" (click)="removeAccommodation(key)" [hidden]="rooms.length <= 1"
                                  class="material-icons"
                                  matRipple>delete</span>
                        </h5>
                        <div class="step">
                            <div class="box-text">
                                <strong>adultos</strong>
                                <span>a partir de {{ dataHeader?.max_age_chd + 1 }} anos</span>
                            </div>
                            <div class="group">
                                <button (click)="actionRooms('remove', 'pax', key)"
                                        [disabled]="room.pax === 1"
                                        aria-label="remover criança" color="primary"
                                        mat-icon-button
                                        mat-stroked-button>
                                    <mat-icon>remove</mat-icon>
                                </button>
                                <input [(ngModel)]="room.pax" readonly type="text">
                                <button (click)="actionRooms('add', 'pax', key)"
                                        [disabled]="room.pax >= (dataHeader?.max_qtd_pax > 0 ? dataHeader?.max_qtd_pax : 10)"
                                        aria-label="adicionar criança"
                                        color="primary" mat-icon-button
                                        mat-stroked-button>
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="step" [hidden]="dataHeader?.hide_chd_form || (dataHeader?.codes?.silbeck && dataHeader?.search_engine?.id == 15)">
                            <div class="box-text">
                                <strong>crianças</strong>
                                <span>menores de {{ dataHeader?.max_age_chd }} anos</span>
                            </div>
                            <div class="group">
                                <button (click)="actionRooms('remove', 'children', key)"
                                        [disabled]="room.children === 0"
                                        aria-label="remover criança" color="primary"
                                        mat-icon-button
                                        mat-stroked-button>
                                    <mat-icon>remove</mat-icon>
                                </button>
                                <input [(ngModel)]="room.children" readonly type="text">
                                <button
                                    [disabled]="room.children === dataHeader?.max_qtd_chd"
                                    (click)="actionRooms('add', 'children', key)"
                                        aria-label="adicionar criança"
                                        color="primary" mat-icon-button
                                        mat-stroked-button>
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let age of [].constructor(room.childrenAges.length); let ind = index" class="step">
                        <div class="box-text">
                            <strong>idade criança {{ind + 1}}</strong>
                        </div>
                        <div class="group">
                            <select class="select-children" [(ngModel)]="room.childrenAges[ind]" [name]="'ageChildren' + (ind + 1)" (ngModelChange)="handleModelChange(room.childrenAges[ind])">
                                <option value="null">idade</option>
                                <ng-template #arrTemplateChildren [ngTemplateOutlet]="arrTemplateChildren"
                                             *ngFor="let n of arrChildren; let value = index">
                                    <option
                                        [ngValue]="value" *ngIf="value >= dataHeader?.min_age_chd && value <= dataHeader?.max_age_chd">
                                        {{ value === 0 ? 'até 1 ano' : value + ' anos' }}
                                    </option>
                                </ng-template>
                            </select>
                        </div>
                    </div>
                </ng-template>

                <div class="new-accommodation-container" [ngClass]="{'justify-content-between':dataHeader.accommodation_type == 3 }">
                    <button *ngIf="dataHeader.accommodation_type == 3" (click)="typeAcomodation = null" matRipple>tipo de acomodação</button>
                    <button *ngIf="dataHeader.search_engine?.id !== 14 && rooms.length != dataHeader?.max_qtd_accommodation && rooms.length < dataHeader?.max_qtd_accommodation || dataHeader?.max_qtd_accommodation === null" (click)="newAccommodation()" matRipple>adicionar acomodação</button>
                </div>
            </div>

            <div class="footer">
                <button (click)="openModal()" class="close-button" matRipple>fechar</button>
                <button
                    (click)="openCoupon($event)"
                    [ngClass]="{'mobile': mobileSearch}" class="search-button" matRipple>
                    avançar
                </button>
            </div>
        </div>
    </div>
    <div (click)="openModal()" *ngIf="openConfigSearch" class="backdrop"></div>
</div>
<div class="guests-wrapper" *ngIf="dataHeader.accommodation_type == 2 || typeAcomodation == 2 ">
    <div (click)="openModal()"
         class="container-guests" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch}">
        <label *ngIf="(!mobileSearch && !otherForm) || (isMobile && !mobileSearch) || lineSearch">Quantos Hóspedes?</label>
        <div class="form-field">
            <div class="container-form-guests">
                <div class="guests" [ngClass]="{'mobile-search': mobileSearch}">
                    <div class="item notranslate mr-3">
                        <figure>
                            <img alt="" class="svg-icon"
                                 src="assets/images/ico-buscador-hospedes.svg" width="20" height="20">
                        </figure>
                        {{ modelRoom.accommodation }}
                        <span translate="yes" *ngIf="searchType < 6 && (( modelRoom.accommodation) > 1) "
                                class="quantity-guest"
                                [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch, 'portal': squaredSearch}">
                                hóspedes
                        </span>
                        <span translate="yes" *ngIf="searchType < 6 && (( modelRoom.accommodation) <= 1)"
                                class="quantity-guest"
                                [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch, 'portal': squaredSearch}">
                                hóspede
                        </span>
                    </div>
                </div>
                <span translate="no" *ngIf="!mobileSearch" class="material-icons">{{ openConfigSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </div>
        </div>
    </div>
    <div [@.disabled]="!isMobile" @slideUpAndDown *ngIf="openConfigSearch" [ngClass]="{'active': openConfigSearch, 'rounded': roundedSearch, 'squared': squaredSearch}" class="box-guest">
        <span translate="no" class="material-icons arrow">arrow_drop_up</span>
        <div class="stepper">
            <div class="inner-stepper">

                <div class="back-button-container">
                    <button matRipple class="back-button" (click)="handleBackButton()">
                        <span translate="no" class="material-icons-outlined">chevron_left</span>
                    </button>
                    <div class="selected-local">
                        <span>{{ selectedLocalAndDates?.name }}</span>
                        <span class="date" *ngIf="selectedLocalAndDates?.selectedDates">
                            {{ selectedLocalAndDates?.selectedDates?.checkin | date: 'dd MMM' }} à {{ selectedLocalAndDates?.selectedDates?.checkout | date: 'dd MMM' }}
                        </span>
                    </div>
                </div>

                <ng-template #TempRooms [ngTemplateOutlet]="TempRooms">
                    <div class="stepper-item">
                        <div class="step">
                            <div class="box-text">
                                <strong>Hóspedes</strong>
                            </div>
                            <div class="group">
                                <button (click)="removeGuests()"
                                        [disabled]="modelRoom.accommodation === 1"
                                        aria-label="remover criança" color="primary"
                                        mat-icon-button
                                        mat-stroked-button>
                                    <mat-icon>remove</mat-icon>
                                </button>
                                <input [(ngModel)]="modelRoom.accommodation" readonly type="text">
                                <button (click)="newGuests()"
                                        [disabled]="modelRoom.accommodation >= (dataHeader?.max_qtd_pax > 0 ? dataHeader?.max_qtd_pax : 10)"
                                        aria-label="adicionar criança"
                                        color="primary" mat-icon-button
                                        mat-stroked-button>
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="new-accommodation-container justify-content-start" *ngIf="dataHeader.accommodation_type == 3">
                    <button (click)="typeAcomodation = null" matRipple>tipo de acomodação</button>
                </div>
            </div>

            <div class="footer">
                <button (click)="openModal()" class="close-button" matRipple>fechar</button>
                <button
                    (click)="openCoupon($event)"
                    [ngClass]="{'mobile': mobileSearch}" class="search-button" matRipple>
                    avançar
                </button>
            </div>
        </div>
    </div>
    <div (click)="openModal()" *ngIf="openConfigSearch" class="backdrop"></div>
</div>
<div class="guests-wrapper" *ngIf="dataHeader.accommodation_type == 3 && typeAcomodation == null">
    <div (click)="openModal()"
         class="container-guests" [ngClass]="{'rounded': roundedSearch, 'squared': squaredSearch}">
        <label *ngIf="(!mobileSearch && !otherForm) || (isMobile && !mobileSearch) || lineSearch">Quantos Hóspedes?</label>
        <div class="form-field">
            <div class="container-form-guests">
                <div class="guests" [ngClass]="{'mobile-search': mobileSearch}">
                    <div class="item notranslate mr-3">
                        <span>{{ !isMobile ? 'Tipo de hospedagem': 'Hospedagem' }}</span>
                    </div>
                </div>
                <span translate="no" *ngIf="!mobileSearch" class="material-icons">{{ openConfigSearch ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </div>
        </div>
    </div>
    <div [@.disabled]="!isMobile" @slideUpAndDown *ngIf="openConfigSearch" [ngClass]="{'active': openConfigSearch, 'rounded': roundedSearch, 'squared': squaredSearch}" class="box-guest">
        <span translate="no" class="material-icons arrow">arrow_drop_up</span>
        <div class="stepper">
            <div class="inner-stepper">
                <div class="stepper-item">
                    <div class="step">
                        <div class="box-text">
                            <strong>Hospedagem privativa</strong>
                            <span>A sua acomodação será quarto exclusivo</span>
                        </div>
                        <button (click)="selectAcomodaction('traditional')"
                                aria-label="Selecionar" color="primary" class="select-acomodation-type">
                            selecionar
                        </button>
                    </div>

                    <div class="step">
                        <div class="box-text">
                            <strong>Hospedagem compartilhada</strong>
                            <span>A sua acomodação será em quarto compartilhado</span>
                        </div>
                        <button (click)="selectAcomodaction('hostel')"
                                aria-label="Selecionar" color="primary" class="select-acomodation-type">
                            selecionar
                        </button>
                    </div>
                </div>
            </div>

            <div class="footer">
                <button (click)="openModal()" class="close-button" matRipple>fechar</button>
            </div>
        </div>
    </div>
    <div (click)="openModal()" *ngIf="openConfigSearch" class="backdrop"></div>
</div>
