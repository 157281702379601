import { environment } from './../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {AppDataService} from './../../../services/app-data.service';
import { Component, OnInit, Input } from '@angular/core';
import {SwiperOptions} from 'swiper';
import {SeoService} from '../../../services/seo.service';
import { InitializerService } from '../../../services/initializer.service';
import { TagManagerService } from '../../../services/tag-manager.service';
import { PromotionDetail, PromotionOffer } from 'projects/new-portal/src/@types/tag-manager';

@Component({
    selector: 'my-portal-promotion',
    templateUrl: './promotion.component.html',
    styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {
    infoData: any;

    promotion: any;
    loading: boolean = true;
    hotelId = this.activatedRouter.snapshot.params.hotelId;

    config: SwiperOptions = {
        autoplay: true,
        effect: 'fade',
        loop: true,
        slidesPerView: 1,
        autoHeight: true,
        pagination: {
            el: '.images-pagination',
            clickable: true,
        },
    };
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appData: AppDataService,
                private activatedRouter: ActivatedRoute,
                private seoService: SeoService,
                private initializerService: InitializerService,
                private tagManagerService: TagManagerService) {
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        setTimeout(() => {
            this.appData.scroll('promotionDetail');
        }, 25);
        this.appData.getPromotion(this.activatedRouter.snapshot.params.id, this.activatedRouter.snapshot.params.hotelId)
            .subscribe((response: any) => {
                this.promotion = response.data;
                const objSeo = {
                    seo: {
                        title: this.promotion.title,
                        description: this.promotion.subtitle,
                        keyWorks: null,
                        image: this.promotion.images[0]
                    }
                };
                this.seoService.updateSeo(objSeo);
                this.loading = false;
                this.tagManagerService.viewPromotion(response.data)
            });

        this.initializerService.currentData.subscribe((response) => {
            this.infoData = response;
        }).unsubscribe();
    }

    public handleSeeOptions(promotion: PromotionDetail, offer: PromotionOffer): void {
        this.tagManagerService.viewOfferEvent(promotion, offer);
    }
}
