<div class="loading-container mt-5 mb-3">
    <my-portal-inner-load class="loading-container mt-5 mb-3" *ngIf="load"></my-portal-inner-load>
</div>

<section class="mt-5 mb-5" *ngIf="(packages?.length || packages?.hotels.length) && !load" >
    <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 row">
        <my-portal-highlighter [module]="moduleData"></my-portal-highlighter>
    </div>

    <div *ngIf="typeEstablishment < 6" >
        <my-portal-packages-default *ngIf="packages?.length <= 2" [packages]="packages" [load]="load" [hotelId]="personId"></my-portal-packages-default>
        <my-portal-packages-carousel *ngIf="packages?.length > 2" [packages]="packages" [load]="load" [initialize]="initialize" [hotelId]="personId"></my-portal-packages-carousel>
        <my-portal-show-all *ngIf="moduleData.landing_page_categoria_id" [route]="'categoria/' + moduleData.slug + '/' + moduleData.landing_page_categoria_id"></my-portal-show-all>
    </div>

    <div *ngIf="typeEstablishment >= 6">
        <mat-tab-group mat-stretch-tabsBo (selectedTabChange)="onTabChange()">
            <ng-template #listHotelPackages [ngTemplateOutlet]="listHotelPackages" *ngFor="let hotel of packages.hotels">
                <mat-tab *ngIf="hotel.promotions.length" [label]="hotel.hotel_title | uppercase">
                    <div class="container-fluid mb-5 mt-5">
                        <my-portal-packages-default *ngIf="hotel.promotions?.length <= 2" [packages]="hotel.promotions" [load]="load" [hotelId]="hotel.hotel_id"></my-portal-packages-default>
                        <my-portal-packages-carousel *ngIf="hotel.promotions?.length > 2" [packages]="hotel.promotions" [load]="load" [hotelId]="hotel.hotel_id" [initialize]="initialize"></my-portal-packages-carousel>
                        <my-portal-show-all *ngIf="moduleData.landing_page_categoria_id" [route]="'categoria/' + moduleData.slug + '/' + moduleData.landing_page_categoria_id"></my-portal-show-all>
                    </div>
                </mat-tab>
            </ng-template>
        </mat-tab-group>
    </div>
</section>


