import {Component, OnInit} from '@angular/core';
import {AppDataService} from '../../../services/app-data.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SeoService} from '../../../services/seo.service';
import {DomSanitizer} from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { environment } from 'projects/new-portal/src/environments/environment';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-generic-pages-detail',
    template: `
        <my-portal-inner-load *ngIf="load"></my-portal-inner-load>
        <section class="mt-5 mb-5 row genericPages" id="genericPages" *ngIf="!load && !error">
            <div class="col-md-9 col-sm-12 genericPages">
                <my-portal-highlighter [module]="{title: detail?.title, description: detail?.sub_title}"></my-portal-highlighter>
                <figure *ngIf="detail?.image && !detail?.images && !detail.video">
                    <img class="img-fluid"
                         [src]="imgServer + 'width=' + 350 + imgServerUrlEnd + detail?.image">
                </figure>
                <my-portal-slide-detail *ngIf="detail?.images && !detail.video" [images]="detail?.images"></my-portal-slide-detail>
                <iframe *ngIf="detail?.video" class="mb-3" [src]="detail?.video" allowfullscreen frameborder="0" height="400"
                        mozallowfullscreen showinfo="1" webkitallowfullscreen width="100%"></iframe>
                <div class="description mt-4 mb-4 text-break" [innerHTML]="detail?.description | sanitizeHtml">
                </div>
                <div class="font d-flex">
                    <p *ngIf="detail?.source" class="ml-auto">Fonte: <a href="{{detail?.source}}">{{detail?.source}}</a></p>
                </div>


                <my-portal-highlighter *ngIf="detail?.maps" [module]="{title: 'Localização'}"></my-portal-highlighter>
                <iframe *ngIf="detail?.maps" class="mb-3" [src]="detail?.maps" allowfullscreen="" aria-hidden="false" frameborder="0" height="950"
                        style="border:0;" tabindex="0" width="100%"></iframe>

                <my-portal-galery-modal *ngIf="detail?.galery" [galleryItem]="gallery"></my-portal-galery-modal>
                <my-portal-video-player *ngIf="detail?.galery_video?.length" [genericPages]="detail?.galery_video" [removeHighlighter]="false"></my-portal-video-player>
                <my-portal-room-events *ngIf="detail?.event_areas" [roomEvents]="detail?.event_areas"></my-portal-room-events>
            </div>

            <div class="col-md-3 triggers">
                <my-portal-triggers></my-portal-triggers>
            </div>

        </section>
        <section class="mt-5 mb-5 pt-5 pb-5 row genericPages" id="genericPages" *ngIf="error">
            <h3>Não foi encontrado conteúdo para esta página.</h3>
        </section>`,
    styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

    detail: any = null;
    gallery = {
        images: []
    };
    load: boolean = true;
    error: boolean = false;
    routerEventsSubscription: Subscription = new Subscription();
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private dataService: AppDataService,
                private activatedRouter: ActivatedRoute,
                private seoService: SeoService,
                private sanitizer: DomSanitizer,
                private router: Router,
                private initializerService: InitializerService) {
    }

    getData(): void {
        this.dataService.getDetailGenericPages(this.activatedRouter.snapshot.params.id)
            .subscribe(
                (response: any) => {
                    this.detail = response.data;
                    this.gallery.images = response.data.galery;
                    this.detail.video = (this.detail.video) ? this.sanitizer.bypassSecurityTrustResourceUrl(this.detail.video) : null;
                    this.detail.maps = (this.detail.maps) ? this.sanitizer.bypassSecurityTrustResourceUrl(this.detail.maps) : null;
                    
                    if (this.detail?.whatsapp) {
                        this.initializerService.changeCurrentWhatsApp(this.detail?.whatsapp);
                    }

                    const objSeo = {
                        seo: {
                            title: this.detail.title,
                            description: this.detail.sub_title,
                            keyWords: null,
                            image: this.detail.image
                        }
                    };
                    this.seoService.updateSeo(objSeo);
                    this.load = false;
                },
                (error: any) => {
                    this.load = false;
                    this.error = true;
                }
            );
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.getData();

        this.routerEventsSubscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd === true) {
                this.load = true;
                this.ngOnInit()
            }
        });
    }

    ngOnDestroy() {
        this.routerEventsSubscription.unsubscribe();
        this.initializerService.changeCurrentWhatsApp(false);
    }

}
