<section class="mt-5 mb-3 promotion" id="promotionDetail">
    <my-portal-inner-load *ngIf="loading"></my-portal-inner-load>
    <div class="title" *ngIf="!loading">
        <my-portal-highlighter [module]="{title: promotion?.title, description: promotion?.subtitle }"></my-portal-highlighter>
        <my-portal-promotion-counter *ngIf="promotion?.count" [date]="promotion?.final_date"></my-portal-promotion-counter>
    </div>
    <div class="row no-gutters promotion-content" *ngIf="!loading">
        <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <div class="banner">
                <swiper [config]="config" [initialize]="true" #usefulSwiper>
                    <div class="swiper-wrapper">
                        <ng-template #PromotionImages [ngTemplateOutlet]="PromotionImages" *ngFor="let image of promotion?.images">
                            <figure class="swiper-slide">
                                <img [src]="imgServer + 'width=' + 833 + imgServerUrlEnd + image" class="img-fluid">
                            </figure>
                        </ng-template>
                    </div>
                    <!-- Add Arrows -->
                    <!--    <div class="swiper-pagination"></div>-->
                    <div class="images-pagination swiper-pagination"></div>
                </swiper>
                <div class="expiration">
                    Data de expiração da oferta: {{promotion?.final_date | date: 'dd/MM/yyyy'}}
                </div>
            </div>

            <div class="description mt-4" [innerHtml]="promotion?.description">
            </div>

            <div class="rooms">
                <my-portal-highlighter [module]="{title: 'Ofertas'}"></my-portal-highlighter>

                <div class="room-container" *ngFor="let room of promotion?.bedrooms">
                    <div class="room-item">
                        <div class="image">
                            <figure>
                                <img class="img-fluid w-100" [alt]="room.name" [src]="imgServer + 'width=' + 207 + imgServerUrlEnd + room.imagem">
                            </figure>
                            <div *ngIf="room?.discount !== 0" class="flag">-{{ room.discount }}%</div>
                        </div>
                        <div class="info">
                            <div class="title">
                                <span class="package-name">Oferta {{ room.nights + 1 }} dia(s) {{ room.nights }} noite(s)</span>
                                <span class="room-name">{{ room.name }}</span>
                            </div>

                            <div class="date">
                                <div class="date-item">
                                    <figure>
                                        <svg xmlns="http://www.w3.org/2000/svg" id="Camada_1" data-name="Camada 1" viewBox="0 0 24 24"><defs><style>.cls-1{fill:none;stroke:#8d8d94;stroke-linecap:round;stroke-linejoin:round;}</style></defs><title>Icones</title><circle class="cls-1" cx="12" cy="6.3" r="4.7" transform="translate(-1.5 7.3) rotate(-32.1)"/><path class="cls-1" d="M4.7,22.4a1.5,1.5,0,0,1-1.5-1.9c.9-4.1.5-7.1,8.8-7.1s7.9,3,8.8,7.1a1.5,1.5,0,0,1-1.5,1.9Z"/></svg>
                                    </figure>
                                    <span>{{room.pax}} {{ room.pax === 1 ? 'Hóspede' : 'Hóspedes' }}</span>
                                </div>
                                <div class="date-item">
                                    <figure>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.3 23.2"><defs><style>.cls-1{fill:none;stroke:#8d8d94;stroke-linecap:round;stroke-miterlimit:10;}.cls-2{fill:#8d8d94;}</style></defs><title>Ativo 1</title><g id="Camada_2" data-name="Camada 2"><g id="Camada_1-2" data-name="Camada 1"><line class="cls-1" x1="0.5" y1="8.1" x2="23" y2="8.1"/><rect class="cls-1" x="16.4" y="0.5" width="2.5" height="4.04" rx="1"/><rect class="cls-1" x="5.3" y="0.5" width="2.5" height="4.04" rx="1"/><path class="cls-1" d="M18.9,2.5h2.8a2.1,2.1,0,0,1,2.1,2V20.7a2.1,2.1,0,0,1-2.1,2H2.5a2,2,0,0,1-2-2V13.6"/><line class="cls-1" x1="7.8" y1="2.5" x2="16.4" y2="2.5"/><path class="cls-1" d="M.5,15V4.5a2,2,0,0,1,2-2H5.3"/><circle class="cls-2" cx="5.7" cy="12.7" r="0.8"/><circle class="cls-2" cx="12.2" cy="12.7" r="0.8"/><circle class="cls-2" cx="18.7" cy="12.7" r="0.8"/><circle class="cls-2" cx="5.7" cy="17.7" r="0.8"/><circle class="cls-2" cx="12.2" cy="17.7" r="0.8"/><circle class="cls-2" cx="18.7" cy="17.7" r="0.8"/></g></g></svg>
                                    </figure>
                                    <div class="chekin-checkout">
                                        <span>Check-in: {{ room.checkin | date: 'dd/MM/yyyy' }}</span>
                                        <span>Check-out {{ room.checkout | date: 'dd/MM/yyyy'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="prices">
                            <div class="content">
                                <div class="price old-price" *ngIf="room.discount">De: {{ room.old_price | currency }}</div>
                                <div class="price">{{ room.discount !== 0 ? 'Por:' : 'A partir de:'}} <br *ngIf="room.discount === 0"> {{ room.price | currency }}</div>
                                <a (click)="handleSeeOptions(promotion, room)" [routerLink]="hotelId ? ['/search', room.checkin, room.checkout, room.pax, hotelId] : ['/search', room.checkin, room.checkout, room.pax]">
                                    <button>ver opções</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="room.description" class="message">{{ room.description }}</div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <my-portal-triggers [infoData]="infoData"></my-portal-triggers>
        </div>
    </div>

</section>
