import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { merge, Subscription } from 'rxjs';
import { InitializerService } from '../../services/initializer.service';
import { CartService } from '../../services/cart.service';
import { filter, tap } from 'rxjs/operators';
import { TagManagerService } from '../../services/tag-manager.service';

@Component({
  selector: 'my-portal-btn-whats-app',
  templateUrl: './btn-whats-app.component.html',
  styleUrls: ['./btn-whats-app.component.scss']
})
export class BtnWhatsAppComponent implements OnInit,OnDestroy {
    private whatsAppInfoSubscription$!: Subscription;
    private positionHandlerSubscription$!: Subscription;

    @Input() footerData: any;
    @Input() infoData : any
    phoneMsgWhatsApp :any;
    isMobile: boolean = this.initializerService.isMobileDevice();
    whatsAppNumber: string = '';
    whatsAppMessage: string = '';
    private initialPosition: 'right' | 'left' = 'right';
    private currentDataCart: any;

    constructor(private router: Router, private initializerService: InitializerService, private readonly cartService: CartService, private readonly tagManagerService: TagManagerService) {}

    ngOnInit(): void {
        this.initialPosition = this.infoData.whatsapp.position.position;

        if (this.isMobile) {
            this.positionHandlerSubscription$ = merge(this.cartService.currentDataCartItems.pipe(tap(cartValue => this.currentDataCart = cartValue)), this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd))).subscribe((values: any) => {
                if (this.currentDataCart.count) {
                    if (values instanceof NavigationEnd && values.url !== '/sale') {
                        this.infoData.whatsapp.position.position = 'left';
                    }

                    if (!(values instanceof NavigationEnd)) {
                        this.infoData.whatsapp.position.position = 'left';
                    }
                } else {
                    this.infoData.whatsapp.position.position = this.initialPosition;
                }

                if (this.router.url === '/sale') {
                    this.infoData.whatsapp.position.position = 'right';
                }

            });
        }


       this.handleWhatsApp();

        this.whatsAppInfoSubscription$ = this.initializerService.currentWhatsAppInfo.subscribe((whatsAppInfo: any) => {
                this.handleWhatsApp(whatsAppInfo);
        })
    }

    handleWhatsApp(genericPageWhatsApp?: any): void {
        const data = genericPageWhatsApp || this.footerData[0].whatsapp;

        if (!data.number) {
            data.number = '';
        }

        this.whatsAppMessage = data.message;
        let whats = data.number.replace(/\D+/g, '').replace(/^0+/, '');
        let whatsVerification = whats.substring(0, 4).includes('+55');

        if(!whatsVerification && this.footerData[0].whatsapp.number.length <= 11){
            const newWhats = `55${this.footerData[0].whatsapp.number}`
            whats = newWhats;
        }
        data.number = whats;
        this.whatsAppNumber = data.number;

        if (data.number.length <= 11) {
            const newWhats = data.number;
            data.number = newWhats;
            this.whatsAppNumber = data.number;
        }

        if(this.infoData.type > 3){
            const clientsArr: any[] = Object.values(this.infoData.whatsapp.message);
            const firstClient: any = clientsArr[0];
            this.phoneMsgWhatsApp = firstClient.text;

            if (genericPageWhatsApp) {
                this.phoneMsgWhatsApp = data.message;
            }
        }
    }

    triggerWhatsAppEvent(): void {
        this.tagManagerService.whatsAppEvent();
    }

    ngOnDestroy(): void {
        if (this.whatsAppInfoSubscription$) {
            this.whatsAppInfoSubscription$.unsubscribe();
        }

        if (this.positionHandlerSubscription$) {
            this.positionHandlerSubscription$.unsubscribe();
        }
    }
}
