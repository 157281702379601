import { DocumentService } from 'projects/new-portal/src/app/services/document.service';
import { DialogCancellationComponent } from './dialog-cancellation/dialog-cancellation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { FormBuilder, FormGroup } from '@angular/forms';
import { AppDataService } from './../../../services/app-data.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { environment } from './../../../../environments/environment';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-restricted-area-reserve',
    templateUrl: './reserve.component.html',
    styleUrls: ['./reserve.component.scss'],
})
export class RestrictedAreaReserveComponent implements OnInit {
    dataReserves: any;
    dataFidelity: any;
    dataPayment: any;
    objHiddenPopover: any = {};
    arrPagination: any = [];
    pageEvent: PageEvent | any;
    currentPage: any;
    paginator: MatPaginator | any;
    loadingData: boolean = false;
    selectedFilterId = null;
    dataReservesError: boolean = false;

    filterStatusData: any = [
        {
            id: null,
            name: 'Todos',
        },
        {
            id: 1,
            name: 'Em analise',
        },
        {
            id: 2,
            name: 'Pago',
        },
        {
            id: 3,
            name: 'Cancelado',
        },
        {
            id: 4,
            name: 'Reservado',
        },
        {
            id: 5,
            name: 'Pagamento recusado',
        },
        {
            id: 6,
            name: 'Pagamento parcial',
        },
        {
            id: 7,
            name: 'Orçamento',
        },
    ];
    userData?: any = JSON.parse(this.documentService.getWindow()?.sessionStorage?.getItem('userData') || '');

    @ViewChild('filterReserveSelect') filterSelect: MatSelect | undefined;

    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;


    constructor(
        private appData: AppDataService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        public documentService: DocumentService,
        private initializerService: InitializerService
    ) { }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.getData(this.userData?.type);
    }

    getData(loginType: any, page?: any, selectedFilter?: any) {
      this.loadingData = true;
        this.appData.getRestrictedAreaReserves(loginType, page, selectedFilter).subscribe(
            (response: any) => {
                this.dataReserves = response.data;

                this.dataReserves.data.forEach((reserve: any) => {                 
                    if (reserve.cancel_policy.date) {
                        const dateSplited = reserve?.cancel_policy?.date.split('-');
                        const formatedDate = dateSplited[2] + '-' + dateSplited[1] + '-' + dateSplited[0];
                        
                        reserve.cancel_policy.date = formatedDate;
                    }
                });

                for (const key in this.dataReserves.data) {
                    this.objHiddenPopover[key] = {
                        orderExpand: false,
                        showResponsiblePopover: false,
                        showCancelationPopover: false,
                    };
                }
                this.loadingData = false;
            },
            (error: any) => {
                this.loadingData = false;
                this.dataReservesError = true;
            }
        );
    }

    onChangeFilter(form: any): void {
        this.getData(this.userData?.type,null, form.id);
        this.selectedFilterId = form.id;
    }

    changePage(page: any): void {
      this.getData(this.userData?.type, page.pageIndex + 1);
    }

    onHandleCancelReserve(reserve: any): void {
        this.openDialogCancellation(reserve);
    }

    openDialogCancellation(reserve: any): void {
        const dialogRef = this.dialog.open(DialogCancellationComponent, {
          width: '500px',
          data: { reserve, 
            
            userType: this.userData?.type},
        });

        dialogRef.afterClosed().subscribe(result => {
           if (result === 'cancelado')  this.getData(this.userData?.type);
        });
    }

}
