import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import {Renderer} from '@angular/compiler-cli/ngcc/src/rendering/renderer';
import {MatDialog} from '@angular/material/dialog';
import {DialogErrorComponent} from '../dialog-error/dialog-error.component';
import {DocumentService} from '../../services/document.service';
import {Observable, Subscription} from 'rxjs';
import {InitializerService} from '../../services/initializer.service';
import {DefaultSnackBarComponent} from '../default-snack-bar/default-snack-bar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import {Location} from '@angular/common';

@Component({
    selector: 'my-portal-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],

    animations: [
        trigger('slideUpAndDown', [
            transition(':enter', [
                style({
                    transform: 'translateY(100%)'
                }),
                animate(200)]
            ),
            transition(':leave', [
                animate(200,
                    style({
                        transform: 'translateY(100%)'
                    })
                )
            ]),
        ]),
    ],
})
export class DatepickerComponent implements OnInit {

    @Input() destination: any;
    @Input() mobileSearch: boolean = false;
    @Input() roundedSearch: boolean = false;
    @Input() squaredSearch: boolean = false;
    @Input() lineSearch: boolean = false;
    @Output() dates = new EventEmitter();
    @Output() panel = new EventEmitter();
    @Input() otherForm: boolean = false;

    personId = Number(this.documentService.getWindow()?.sessionStorage?.getItem('person'));

    typeSearch = Number(this.documentService.getWindow()?.sessionStorage.getItem('typeSearch')) >= 6;
    isMobile: boolean = this.initializerService.isMobileDevice();

    load: boolean = true;
    arePricesLoading: boolean = true;
    week: any[] = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'];
    calendar: any;
    calendarWeek: any[] = [];
    openDatePicker: boolean = false;
    today = moment().format('YYYY-MM-DD');
    openConfigSearch = false;
    openCouponSearch: boolean = false;
    rooms: any[] = [
        {
            pax: 1,
            children: 0,
            childrenAges: [],
        },
    ];
    navigation: any = {
        prev: {
            hidden: true,
            disabled: false,
        },
        next: {
            hidden: false,
            disabled: false,
        },
    };

    objMonth: any = {
        start: null,
        end: null,
    };
    objectDate: any = {
        checkin: moment().format('YYYY-MM-DD'),
        checkout: moment().add(1, 'days').format('YYYY-MM-DD'),
    };
    objectRange: any = {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(1, 'days').format('YYYY-MM-DD'),
    };
    objectRestriction: any = {
        unavailable: true,
        closedCheckIn: true,
        closedCheckOut: false,
    };
    daysAdvanced: number = 0;
    initUrl: any;
    selectedLocal: any;
    selectedLocalAndDates: any;

    observations: any = {};
    hotelInfo: any
    date: any;
    info: any;

    constructor(
        private dataService: AppDataService,
        public dialog: MatDialog,
        private documentService: DocumentService,
        private initializerService: InitializerService,
        private snackBar: MatSnackBar,
        private activatedRouter: ActivatedRoute,
        private router: Router,
        private location: Location
    ) {

        this.initUrl = location.path();
        activatedRouter.paramMap.subscribe(params => {
            if (location.path().includes('/search/')) {
                this.resetSelectedDates();
                this.initializerDatesBaseUrl();
            }
        });

        router.events.subscribe((val) => {
            if (location.path().includes('/search/')) {
                this.resetSelectedDates();
                this.initializerDatesBaseUrl();
            }
        });
    }

    ngOnInit(): void {
        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    this.date = moment().add(data.advance_reservation, 'days').format('YYYY-MM-DD');
                    this.daysAdvanced = data.advance_reservation;
                    this.info = data;
                    if (this.daysAdvanced) {
                        if (!this.location.path().includes('/search/')) {
                            this.objectDate = {
                                checkin: moment().add(data.advance_reservation, 'days').format('YYYY-MM-DD'),
                                checkout: moment().add((data.advance_reservation + 1), 'days').format('YYYY-MM-DD'),
                            };
                            this.objectRange = {
                                start: moment().add(data.advance_reservation, 'days').format('YYYY-MM-DD'),
                                end: moment().add((data.advance_reservation + 1), 'days').format('YYYY-MM-DD'),
                            };

                            setTimeout(() => {
                                this.initDates();
                            }, 25);
                        } else {
                            this.initializerDatesBaseUrl();
                        }

                        this.dates.emit(this.objectRange);
                    }

                    // this.initializerCalendar(this.date);
                    this.today = this.date;
                    this.initializerService.currentControlFormSearch.subscribe(
                        (value: any) => {
                            if (value) {
                                if (value.type === 'local') {
                                    this.openCalendar();
                                }

                                if (value.hotelInfo){
                                    this.hotelInfo = value.hotelInfo;
                                    this.destination = value.hotelInfo;
                                }
                            }
                        }
                    );
                }
            })
            .unsubscribe();

        this.initializerService.currentControlLocalSelected
            .subscribe(
                (value: any) => {
                    this.selectedLocal = value;
                    this.selectedLocalAndDates = value;
            }
        );
    }

    initializerCalendar(params?: any, flag?: boolean): void {
        const obj = {
            first_day: (params) ? params : moment().startOf('month').format('YYYY-MM-DD'),
            pessoa_id: this.personId
        };
        // (params) ? params : moment().startOf('month').format('YYYY-MM-DD');
        this.calendarWeek = this.createWeek(this.createMonth(
            this.createClearMonth((params) ? params : moment().startOf('month').format('YYYY-MM-DD'))
        ), params);
        this.load = false;



        setTimeout(() => {
            if (this.destination) {
                if (this.destination.type === 'hotel') {
                    obj.pessoa_id = this.destination.id;
                }
            }

            if (this.selectedLocalAndDates) {
                if (this.selectedLocalAndDates.type === 'localHotel') {
                    obj.pessoa_id = this.selectedLocalAndDates.id;
                }
            }

            this.dataService.getDatepicker(obj).subscribe((response: any) => {
                this.dataService
                    .getDatepickerDataJson(response.data)
                    .subscribe(
                        (responseJson: any) => {
                            this.calendar = responseJson;

                                for (const [key, item] of Object.entries(this.calendar)) {
                                    if (this.calendar[key].restriction) {
                                        this.calendar[key].restriction.restrictions = {
                                            1: null,
                                            2: null,
                                            3: null,
                                            4: null,
                                            5: null,
                                        };

                                        if (this.calendar[key].restriction.rules) {
                                            for (const ruleItem of this.calendar[key].restriction.rules) {
                                                this.calendar[key].restriction.restrictions[ruleItem.id] = ruleItem;
                                            }
                                        }
                                    }
                                }

                                if (response) this.arePricesLoading = false;

                                if (flag) {
                                    this.navigation = {
                                        prev: {
                                            hidden: Object.keys(this.calendar)[0] <= this.today,
                                            disabled: false,
                                        },
                                        next: {
                                            hidden: false,
                                            disabled: false,
                                        },
                                    };
                                }

                                if (responseJson) this.arePricesLoading = false;

                                if (!flag) {
                                    setTimeout(() => {
                                        this.checkRangeDates(this.objectDate.checkin, this.objectDate.checkout);
                                    }, 50);
                                }

                                if (!params) {
                                    setTimeout(() => {
                                        this.initDates(
                                            this.objectDate.checkin,
                                            this.objectDate.checkout
                                        );
                                    }, 50);
                                }
                            },
                            (error) => {
                                this.arePricesLoading = false;
                            }
                        );
                },
                (error: any) => {
                    this.arePricesLoading = false;
                }
            );
        }, 50);
    }

    initDates(start?: string, end?: string): void {
        const dates = {
            start: (!start) ? moment().add(this.daysAdvanced, 'days').format('YYYY-MM-DD') : start,
            end: (!end) ? moment().add((this.daysAdvanced + 1), 'days').format('YYYY-MM-DD') : end
        };

        this.dataService.checkDates(
            `.date-${dates.start}`,
            'start-date'
        );
        this.dataService.checkDates(
            `.date-${dates.end}`,
            'end-date'
        );

        if (dates?.start && dates?.end) {
            const diff = moment(dates.end).diff(this.objectRange.start, 'days');

            for (let i = 1; i <= diff - 1; i++) {
                const newDate = moment(this.objectRange.start,'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                this.dataService.checkDates(`.date-${newDate}`, 'entry');
            }
        }
    }

    checkRangeDates(start: string, end?: string): void {
        this.dataService.checkDates(`.date-${start}`, 'start-date');

        if (!end) {
            this.dataService.checkDates('.entry', 'entry', true);
        }

        if (start && end) {
            this.dataService.checkDates(`.date-${end}`, 'end-date');

            const diff = moment(end).diff(start, 'days');

            for (let i = 1; i <= diff - 1; i++) {
                const newDate = moment(start, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                this.dataService.checkDates(`.date-${newDate}`, 'entry');
            }
        }
    }

    openCalendar(close?: boolean): void {
        this.openDatePicker = !this.openDatePicker;

        if (this.isMobile && this.info.whatsapp.active) {
            const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
            if (btnWhatsapp) {
                btnWhatsapp.style.zIndex = -1;
            }
        }

        const body: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-drawer-content');
        const gallery: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.mat-tab-body.mat-tab-body-active');

        if (this.openDatePicker) {
            setTimeout(() => {
                this.dataService.showAskSuites(true);
                const element: HTMLElement | any = this.documentService.getWindow()?.document.getElementById('search');
                (element as HTMLElement).scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            }, 300);

            body.style = 'overflow: hidden';
            if (gallery) {
                gallery.style.zIndex = 0;
            }
            if (this.destination) {
                this.initializerCalendar();
            } else {
                this.initializerCalendar(this.date)
            }
        } else {
            this.dataService.showAskSuites(false);
            if (!close) {
                this.panel.emit(true);
            }
            body.style = 'overflow: auto';
            if (gallery) {
                gallery.style.zIndex = 1;
            }
            if (this.isMobile && this.info.whatsapp.active) {
                const btnWhatsapp: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.btn-whats-app');
                if (btnWhatsapp) {
                    btnWhatsapp.style.zIndex = 3;
                }
            }
        }
    }


    createMonth(dates?: any): any {
        const arrDates = dates;
        const initalDate = arrDates[0];
        const month = [];
        const arrFirstDate = [
            moment(initalDate).startOf('month').format('YYYY-MM-DD'),
            moment(initalDate)
                .add(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD'),
        ];

        this.objMonth.start = moment(initalDate)
            .startOf('month')
            .format('YYYY-MM-DD');
        this.objMonth.end = moment(initalDate)
            .add(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD');

        for (const date of arrFirstDate) {
            month.push(this.createDays(date, dates));
        }

        return month;
    }

    createDays(date: string, dates: any): any[] {
        const daysMonth = [];
        const beforeMonth = [];
        const endDayMonth = moment(date, 'YYYY-MM-DD')
            .endOf('month')
            .format('YYYY-MM-DD');


        for (let i = 1; i <= moment(date).isoWeekday(); i++) {

            const subDate = moment(date, 'YYYY-MM-DD')
            .subtract(i, 'day')
            .format('YYYY-MM-DD');

            beforeMonth.push({
                date: subDate,
                weekDay: moment(subDate).isoWeekday(),
                price: dates[subDate] ? dates[subDate].price : null,
                restriction: dates[subDate]
                    ? dates[subDate].restriction
                    : null,
                disabled: subDate > endDayMonth,
            });
        }

        const quantityAfterDates = 42 - (beforeMonth.length + moment(date).daysInMonth());

        for (
            let i = 0;
            i <
            moment(date).daysInMonth() + quantityAfterDates;
            i++
        ) {
            const endDate = moment(date, 'YYYY-MM-DD')
                .add(i, 'day')
                .format('YYYY-MM-DD');

            daysMonth.push({
                date: endDate,
                weekDay: moment(endDate).isoWeekday(),
                price: dates[endDate] ? dates[endDate].price : null,
                restriction: dates[endDate] ? dates[endDate].restriction : null,
                disabled: endDate > endDayMonth,
            });
        }

        const lastArray = beforeMonth.reverse().concat(daysMonth);

        this.load = false;

        return lastArray;
    }

    createWeek(months: any, date: string): any[] {
        const calendar = [];
        let countMonth = 0;
        for (const month of months) {
            calendar.push({
                name: moment(date)
                    .startOf('month')
                    .add(countMonth, 'month')
                    .format('YYYY-MM-DD'),
                startDate: moment(date)
                    .add(countMonth, 'month')
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                endDate: moment(date).add(countMonth, 'month')
                    .endOf('month')
                    .format('YYYY-MM-DD'),
                weeks: this.dataService.createWeek(month),
            });
            countMonth++;
        }
        return calendar;
    }

    selectDate(date: any, event: any): void {
        let restriction = null;
        if (this.calendar) {
            if (this.calendar[date]) {
                restriction = this.calendar[date].restriction;
            }
        }

        if (!this.objectRange.start) {
            if (this.objectRange.start >= this.today) {
                this.objectDate.checkin = date;
                this.objectRange.start = date;
                this.dataService.checkDates(`.date-${date}`, 'start-date');
            }
        } else {
            this.objectDate.end = null
            this.objectDate.checkout = null;

            if (this.objectRange.start < date && !this.objectRange.end) {
                this.objectRestriction.unavailable = true;
                this.objectRestriction.closedCheckIn = true;

                if (this.restriction(restriction, date, true)) {
                    this.objectDate.checkout = date;
                    this.objectRange.end = date;

                    const diff = moment(date).diff(this.objectRange.start, 'days');

                    for (let i = 1; i <= diff - 1; i++) {
                        const newDate = moment(this.objectRange.start,'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                        this.dataService.checkDates(`.date-${newDate}`, 'entry');
                    }

                    this.dataService.checkDates(`.date-${date}`, 'end-date');
                    this.dates.emit(this.objectRange);
                    this.objectRestriction.closedCheckOut = false;
                }
            } else {
                if (this.restriction(restriction, date, false)) {
                    this.objectRestriction.unavailable = false;
                    this.objectRestriction.closedCheckIn = false;
                    this.objectRestriction.closedCheckOut = true;

                    if (date >= this.today) {
                        this.dataService.checkDates('.start-date', 'start-date', true);
                        this.dataService.checkDates('.end-date', 'end-date', true);
                        this.dataService.checkDates('.entry', 'entry', true);

                        this.objectDate.checkin = date;
                        this.objectRange.start = date;
                        this.objectRange.end = null;

                        this.dataService.checkDates(`.date-${date}`,'start-date');
                        this.initializerService.changeControlLocalSelected({
                            ...this.selectedLocal,
                            selectedDates: this.objectDate,
                        });
                    }
                }
            }
        }
    }

    changeMonth(type: string, flag?: boolean): void {
        let date = null;
        if (!flag) {
            if (!this.navigation[type].disabled) {
                this.load = true;
                switch (type) {
                    case 'prev':
                        // this.navigation[type].disabled = true;
                        date = moment(this.objMonth.start)
                            .subtract(1, 'month')
                            .format('YYYY-MM-DD');
                        break;
                    case 'next':
                        // this.navigation[type].disabled = true;
                        if (this.navigation.prev.hidden) {
                            this.navigation.prev.hidden = false;
                            // this.navigation.prev.disabled = true;
                        }
                        date = moment(this.objMonth.start)
                            .add(1, 'month')
                            .format('YYYY-MM-DD');
                        break;
                }

                setTimeout(() => {
                    this.checkRangeDates(this.objectDate.checkin, this.objectDate.checkout);
                }, 50);

                this.initializerCalendar(date, true);
            }
        } else {
            this.load = true;
            this.consultCalendarMobile(
                moment(this.objMonth.end, 'YYYY-MM-DD')
                    .add(1, 'month')
                    .format('YYYY-MM-DD')
            );
        }
    }

    consultCalendarMobile(startDate: string): void {
        const newDates = this.createWeek(
            this.createMonth(this.createClearMonth(startDate)),
            startDate
        );
        const calendar = this.calendarWeek;
        this.calendarWeek = calendar.concat(newDates);
        const obj = {
            first_day: startDate,
            pessoa_id: this.personId
        };

        if (this.destination) {
            if (this.destination.type === 'hotel') {
                obj.pessoa_id = this.destination.id;
            }
        }

        this.dataService.getDatepicker(obj)
            .subscribe((response: any) => {
                this.dataService.getDatepickerDataJson(response.data)
                    .subscribe((responseJson: any) => {
                        this.calendar = {...this.calendar, ...responseJson};
                        this.load = false;

                        for (const [key, item] of Object.entries(this.calendar)) {
                            if (this.calendar[key].restriction) {
                                this.calendar[key].restriction.restrictions = {
                                    1: null,
                                    2: null,
                                    3: null,
                                    4: null,
                                    5: null
                                };

                                if (this.calendar[key].restriction.rules) {
                                    for (const ruleItem of this.calendar[key].restriction.rules) {
                                        this.calendar[key].restriction.restrictions[ruleItem.id] = ruleItem
                                    }
                                }
                            }
                        }
                    });
            });
    }

    createClearMonth(startDate: string): any[] {
        const startMonth = moment(startDate).daysInMonth();
        const endMonth = moment(startDate).add(1, 'month').daysInMonth();
        const arrMonth: any[] = [];
        for (let i = 0; i < startMonth + endMonth; i++) {
            const endDate = moment(startDate, 'YYYY-MM-DD')
                .add(i, 'day')
                .format('YYYY-MM-DD');
            arrMonth.push(endDate);
        }
        return arrMonth;
    }

    onMouseHoverDate(event: any, hoverDay: string): void {
        if (this.calendar && this.calendar[hoverDay]) {
            const { start: checkIn, end: checkOut } = this.objectRange;
            const restriction = this.calendar[hoverDay]?.restriction;
            const dateRules = restriction?.rules;
            const isClosedDate = restriction?.closed;
            const hasDates = Boolean(checkIn && checkOut);
            const isValidHoverForCheckOut = Boolean(checkIn && !checkOut && checkIn < hoverDay);
            const retroativeCheckInDate = Boolean(checkIn && !checkOut && checkIn > hoverDay);
            const isCheckInSelectedDate = checkIn === hoverDay;
            const isCheckOutSelectedDate = checkOut === hoverDay;
            const observations: string[] = [];

            if (isCheckInSelectedDate || isCheckOutSelectedDate) {
                if (isCheckInSelectedDate) {
                    observations.push('Check-in');
                }

                if (isCheckOutSelectedDate) {
                    observations.push('Check-out');
                }

                this.observations[hoverDay] = observations.join('\n');

                return;
            }

            if (hasDates || retroativeCheckInDate) {
                observations.push('Check-in');

                if (dateRules) {
                    for (const ruleItem of dateRules) {
                        if ([1, 2, 5].includes(ruleItem.id)) {
                            observations.push(ruleItem.message_full);
                        }

                        if (ruleItem.id === 3) {
                            observations.push('Fechado check-in');
                        }

                        if (isClosedDate && !dateRules) {
                            observations.push('Indisponível');
                        }
                    }
                }
            }

            if (isValidHoverForCheckOut) {
                const differenceBetweenDates = moment(hoverDay).diff(checkIn, 'days');
                const numberOfNights = `${differenceBetweenDates} Noite${differenceBetweenDates > 1 ? 's' : ''}`;
                const isUnavailableDate = isClosedDate && !dateRules;
                const unavailableDates = [];

                for (let i = 1; i <= differenceBetweenDates; i++) {
                    const entryDate = moment(checkIn, 'YYYY-MM-DD').add(i, 'days').format('YYYY-MM-DD');

                    if (this.calendar[entryDate]?.restriction?.closed && !this.calendar[entryDate]?.restriction?.rules) {
                        unavailableDates.push(entryDate);
                    }
                }

                if (unavailableDates.length > 0 && unavailableDates[0] === hoverDay) {
                    observations.push('Check-out');
                    observations.push(`${numberOfNights}`);

                    this.observations[hoverDay] = observations.join('\n');
                    return;
                }

                if (isUnavailableDate) {
                    observations.push('Indisponível');
                    this.observations[hoverDay] = observations.join('\n');
                    return;
                }

                observations.push('Check-out');
                observations.push(`${numberOfNights}`);

                if (dateRules) {
                    for (const ruleItem of dateRules) {
                        if ([1, 5].includes(ruleItem.id)) {
                            observations.push(ruleItem.message_full);
                        }

                        if (ruleItem.id === 4) {
                            observations.push('Fechado check-out');
                        }
                    }
                }
            }

            this.observations[hoverDay] = observations.join('\n');
        }
    }

    onMouseLeaveDate(event: MouseEvent, hoverDay: string): void {
        this.observations[hoverDay] = null;
    }

    restriction(data: any, date: string, checkout: boolean): boolean {
        const objRestriction: any = this.dataService.restriction(data, date, checkout, this.calendar, this.objectRange.start);

        if (objRestriction.message) {
            this.openDialog(objRestriction.message);
        }

        if (objRestriction.dates) {
            this.objectRange = {
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(1, 'days').format('YYYY-MM-DD')
            };
        }

        if (!objRestriction.isValid) {
            this.dataService.checkDates('.start-date', 'start-date', true);
            this.dataService.checkDates('.end-date', 'end-date', true);
            this.dataService.checkDates('.entry', 'entry', true);

            this.objectDate = {
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(1, 'days').format('YYYY-MM-DD'),
                checkin: null,
                checkout: null
            };

            this.objectRange = {
                start: moment().format('YYYY-MM-DD'),
                end: moment().add(1, 'days').format('YYYY-MM-DD')
            };

            this.objectRestriction = {
                unavailable: true,
                closedCheckIn: true,
                closedCheckOut: false
            };
        }

        return objRestriction.isValid;
    }

    openDialog(message: string): void {
        this.dialog.open(DialogErrorComponent, {
            data: {
                error: message,
            },
            minWidth: '250px',
            autoFocus: false,
        });
    }

    openCoupon(event: any): void {
        const valid = this.checkChildrenAge();
        if (valid) {
            setTimeout(() => {
                this.initializerService.changeControlFormSearch({
                    type: 'coupon',
                });
            }, 100);

            this.openConfigSearch = false;
            this.openCouponSearch = true;
        } else {
            this.openSnackBar('Informe a idade das criança(s)');
        }
    }

    openConfig(event: any): void {

        if (this.objectDate.end == null && this.objectDate.checkout == null) {
            this.openDialog('Selecione a data de check-out');
        } else {
            this.openCalendar(true);

            setTimeout(() => {
                this.initializerService.changeControlFormSearch({
                    type: 'guests',
                });

                this.initializerService.changeControlLocalSelected({
                    ...this.selectedLocal,
                    selectedDates: this.objectDate,
                });
            }, 100);
        }
    }

    checkChildrenAge(): boolean {
        let valid = true;
        const selects: HTMLElement | any = this.documentService
            .getWindow()
            ?.document.querySelectorAll('.select-children');
        for (const accom of this.rooms) {
            if (accom.children) {
                for (const age of accom.childrenAges) {
                    const el: HTMLElement | any =
                        selects[accom.childrenAges.indexOf(null)];
                    if (el) {
                        el.classList.add('required');
                    }
                    if (!age) {
                        valid = false;
                    }
                }
            }
        }
        return valid;
    }

    openSnackBar(msg: string): void {
        this.snackBar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg,
            },
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            panelClass: 'error-snack-bar',
            duration: 2500,
        });
    }

    initializerDatesBaseUrl(): void {
        if (this.location.path().includes('/search/')) {
            const url = this.location.path().replace('/search/', '').split('/');
            this.objectDate = {
                checkin: url[0],
                checkout: url[1]
            };
            this.objectRange = {
                start: url[0],
                end: url[1]
            };

            setTimeout(() => {
                this.initDates(this.objectRange.start, this.objectRange.end);
                this.dataService.checkDates(
                    `.date-${this.objectRange.start}`,
                    'start-date'
                );
                this.dataService.checkDates(
                    `.date-${this.objectRange.end}`,
                    'end-date'
                );
            }, 500);
        }
    }

    handleBackButton(): void {
        this.openCalendar(true);

        if (this.typeSearch) {
            setTimeout(() => {
                this.initializerService.changeControlFormSearch({
                    type: 'localBack',
                });
            }, 100);
        }
    }

    resetSelectedDates(): void {
        const elementsArr = ['start-date', 'end-date', 'entry'];
        for (const element of elementsArr) {
            this.dataService.checkDates(
                `.${element}`,
                element,
                true
            );
        }
    }
}
