import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SanitizeXssService } from 'projects/new-portal/src/app/services/sanitize-xss.service';

@Component({
    selector: 'my-portal-dialog-share',
    templateUrl: './dialog-share.component.html',
    styleUrls: ['./dialog-share.component.scss'],
})
export class DialogShareComponent implements OnInit {
    inputValue: any;
    couponForm: FormGroup;
    loading: boolean = false;
    dataCoupon: any;
    errorMessage: any;

    constructor(
        public dialogRef: MatDialogRef<DialogShareComponent>,
        private formBuilder: FormBuilder,
        private xssSanitizerService: SanitizeXssService,
        private appData: AppDataService,
        @Inject(MAT_DIALOG_DATA) public data: DialogShareComponent | any
    ) {
        this.couponForm = this.formBuilder.group({
            name: ['', Validators.required],
            mail: ['',
              Validators.required
            ],
        });
    }

    ngOnInit(): void {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(form: any): void {
        this.loading = true;
        this.handleSendVoucherToEmail(this.data.voucherId, this.xssSanitizerService.sanitizeXSS(form.value.name), this.xssSanitizerService.sanitizeXSS(form.value.mail));
    }

    handleSendVoucherToEmail(voucherId: number, name: any, mail: any): void {
        this.appData.postRestrictedAreaShareVoucher(voucherId, name, mail).subscribe(
            (response: any) => {
                this.loading = false;
                this.dataCoupon = response.data;
                this.couponForm.reset();
            },
            (error) => {
                this.loading = false;
                this.errorMessage = true;
            }
        );
    }
}
