import {Component, OnInit} from '@angular/core';
import {AppDataService} from '../../../services/app-data.service';
import {ActivatedRoute} from '@angular/router';
import { environment } from './../../../../environments/environment';
import { InitializerService } from '../../../services/initializer.service';

@Component({
    selector: 'my-portal-generic-pages-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class GenericPagesListComponent implements OnInit {

    landingPage: any = null;
    load: boolean = true;
    isSafari: any = this.initializerService.isSafari();

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any;

    constructor(private appService: AppDataService,
                private activatedRoute: ActivatedRoute,
                private initializerService: InitializerService,) {
        this.activatedRoute.paramMap.subscribe(params => {
            this.ngOnInit();
            this.load = true;
        });
    }

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);
        this.appService.getGenericPagesList(this.activatedRoute.snapshot.params.id)
            .subscribe((response: any) => {
                if (response.data) {
                    this.landingPage = response.data;
                    if (this.landingPage.type.id === 3 || this.landingPage.type.id === 2 || this.landingPage.type.id === 1) {
                        for (const item of this.landingPage.items) {
                            item.slug = this.appService.createSlugUrl(item.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                        }
                    }
                    this.load = false;
                }
            });
    }

}
