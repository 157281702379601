<section class="mt-5 mb-5 banner" >
    <div class="row" *ngIf="!loading">
        <div class="col text-center" *ngFor="let banner of banners">
            <div *ngIf="banner.html" [innerHTML]="banner.sanitize"></div>
        </div>

        <div *ngFor="let banner of arrBanners" class="arrBanners">
            <div *ngIf="!isMobile && !banner.html && banner.img?.length < 4 ; else mobileBanner" >
                <div class="arrBanners">
                    <div class="p-2" *ngFor="let img of banner.img; let key = index">
                        <a [href]="img?.link" [target]="img?.target">
                            <img [src]="imgServer + 'width=' + 1110 + imgServerUrlEnd + img.nome_img" alt="banner" class="img-fluid" width="1110" height="auto">
                        </a>
                    </div>
                </div>
            </div>
            <ng-template #mobileBanner>
                <div *ngFor="let banner of arrBanners">
                    <div>
                        <swiper [config]="configBanner" [initialize]="initializer">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide p-3" *ngFor="let img of banner.img; let key = index">
                                   <a [href]="img?.link" [target]="img?.target">
                                    <img [src]="imgServer + 'width=' + 1110 + imgServerUrlEnd + img.nome_img" alt="banner" class="img-fluid" width="555" height="auto">
                                   </a>
                                </div>
                            </div>
                            <div class="pagination-accommodation mt-4"></div>
                        </swiper>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <my-portal-loading *ngIf="loading"></my-portal-loading>
</section>
