import { ThisReceiver } from '@angular/compiler';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwiperOptions } from 'swiper';
import {AppDataService} from '../../services/app-data.service';
import {DocumentService} from '../../services/document.service';
import { InitializerService } from '../../services/initializer.service';
import { SeoService } from '../../services/seo.service';
import { DialogAboutComponent } from './dialog-about/dialog-about.component';
import { environment } from './../../../environments/environment';

@Component({
    selector: 'my-portal-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
    @Input() moduleData: any;
    @Input() personId: any | null;
    @Input() search: any;
    @Input() rates: any | null;
    @Input() showLogo: boolean | null = true;
    @Input() expanded?: boolean = false;
    @Output() infoAbout = new EventEmitter();
    typeSearch: number | null = Number(
        this.documentService.getWindow()?.sessionStorage.getItem('typeSearch')
    );
    portal: boolean | null = null;
    load: boolean = true;
    about: any;
    showText: boolean = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();
    isSafari: any = this.initializerService.isSafari();
    amenities: any[] = [
        {
            name: 'Ar condicionado',
            icon: 'ar-condicionado.png',
        },
        {
            name: 'Piscina Térmica',
            icon: 'piscina.png',
        },
        {
            name: 'Tv a Cabo',
            icon: 'tv.png',
        },
        {
            name: 'Cofre',
            icon: 'cofre.png',
        },
        {
            name: 'Lan House',
            icon: 'lan-house.png',
        },
        {
            name: 'Lavanderia',
            icon: 'lavanderia.png',
        },
    ];
    hiddenShowMore: boolean = false;
    info: any = null;
    hiddenAbout: boolean = false;

    config: SwiperOptions = {
        autoplay: true,
        pagination: { el: '.pagination-about', clickable: true },
      };

    session_markers: any;

    imgServer: string = this.isSafari ? environment.IMAGES_ENDPOINT_SAFARI : environment.IMAGES_ENDPOINT;
    imgServerUrlEnd: any ;

    constructor(
        private appData: AppDataService,
        private documentService: DocumentService,
        public dialog: MatDialog,
        private initializerService: InitializerService,
        private seoService: SeoService,
    ) {}

    ngOnInit(): void {
        this.imgServerUrlEnd = this.initializerService.setImageQuality(environment.IMAGES_END_URL);

        this.portal = this.typeSearch === 6 && this.moduleData;
        this.search = (this.search) ? this.search : false;
        this.appData
            .getDataAbout('about', this.personId, true)
            .subscribe((response: any) => {
                this.about = response.data;
                this.sharedInfoAbout(this.about.data_establishment);

                if (this.personId) {
                    const objSeo = {
                        seo: {
                            title: this.about.data_establishment.name,
                            description: this.about.data_establishment.description,
                            keyWords: null,
                            image: this.about.data_establishment.url[0]
                        }
                    };
                    this.seoService.updateSeo(objSeo);
                }

                setTimeout(() => {
                    this.load = false;
                    const element: HTMLElement | any = this.documentService.getWindow()?.document.querySelector('.line-clamp-9');

                    if (element) {
                        if (element.offsetHeight < 65) {
                            this.hiddenShowMore = true;
                        }
                    }

                }, 500);
            });

        this.initializerService.currentData.subscribe((data: any) => {
            this.session_markers = data.session_markers;
            if (data) {
                this.info = data;
            }
        });

        if(this.expanded) {
            this.showText = true;
        }
    }

    sharedInfoAbout($event: any): void {
        this.infoAbout.emit($event);
    }

    openDialogAbout(description: any): void {
        this.dialog.open(DialogAboutComponent, {
            data: {
                description,
            },
            maxWidth: '760px',
            autoFocus: false,
            width: '90vw'
        });
    }

    handleViewMore(description: string): void {
        if (this.getScreenWidth) {
            this.openDialogAbout(description);
        } else {
            this.showText = !this.showText;
        }
    }
}
