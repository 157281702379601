import {Component, Input, OnInit} from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { AppDataService } from '../../services/app-data.service';

@Component({
    selector: 'my-portal-info',
    template: `
        <div class="content-trigger contact" *ngIf="phone?.length">
            <div class="icon-container">
                <figure>
                    <svg id="Camada_1" stroke="#fff" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <defs>
                            <style>.reservation-central-1 {
                                fill: none;
                                stroke-miterlimit: 10;
                                stroke-width: 0.5px;
                            }</style>
                        </defs>
                        <title>ico-central-reservas</title>
                        <path class="reservation-central-1"
                              d="M14.2,18.2a.9.9,0,0,1-.9.9h-.9a1,1,0,0,1-1-.9h0a1,1,0,0,1,1-.9h.9a.9.9,0,0,1,.9.9Z"/>
                        <path class="reservation-central-1" d="M19.4,9.3a.9.9,0,0,1,.9.9v1.9a.9.9,0,0,1-.9.9h-.9"/>
                        <path class="reservation-central-1" d="M5.8,9.3a.9.9,0,0,0-.9.9v1.9a.9.9,0,0,0,.9.9h.9"/>
                        <path class="reservation-central-1" d="M8.6,17v2.5a1.6,1.6,0,0,1-.9,1.4L2.5,22.4"/>
                        <path class="reservation-central-1" d="M15.7,19.1v.5a1.3,1.3,0,0,0,.9,1.3l4.7,1.5"/>
                        <path class="reservation-central-1" d="M18.5,9.8l-1.1-.9a2.7,2.7,0,0,0-1.8-.5H11.4A3.6,3.6,0,0,1,8.6,6.5"/>
                        <path class="reservation-central-1"
                              d="M10.1,18.1A4.9,4.9,0,0,1,6.7,14V7a4.7,4.7,0,0,1,4.7-4.7h2.4A4.7,4.7,0,0,1,18.5,7v7a4.8,4.8,0,0,1-4.3,4.2"/>
                    </svg>
                </figure>
            </div>
            <h2>Central de reservas</h2>
            <p>Se preferir, ligue para fazer a sua reserva!</p>

            <div class="phone">
                <a *ngFor="let phoneItem of phone" [href]="'tel:'+phoneItem.number" target="_blank">
                    <span *ngIf="phoneItem.type === 'landlinePhone'">
                        {{ phoneItem.number | mask: (phoneItem.ddi ? '+00 (00) 0000-0000' : '(00) 0000-0000') }}
                    </span>
                    <span *ngIf="phoneItem.type === 'mobilePhone' ">
                        {{ phoneItem.number | mask: (phoneItem.ddi ? '+00 (00) 0 0000-0000' : '(00) 0 0000-0000')}}
                    </span>
                    <span *ngIf="phoneItem.type === 'tollFree'">
                        {{ phoneItem.number | mask: '0000 000 0000'}}
                    </span>
                </a>
            </div>

<!--            <p>Estamos disponíveis <strong>24 horas </strong>por dia <strong>7 dias </strong>por semana, de <strong>segunda à-->
<!--                segunda</strong></p>-->
        </div>

        <a [href]="'https://api.whatsapp.com/send?phone='+whatsapp.number + '&text='+whatsapp.message" target="_blank" *ngIf="whatsapp && data?.whatsapp?.active && data?.type === 6">
            <div class="content-trigger whatsapp" *ngIf="whatsapp">
                <div class="icon-container">
                    <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="24" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
                    </figure>
                </div>
                <h2>Atendimento via WhatsApp</h2>
                <p><a [href]="'https://api.whatsapp.com/send?phone='+whatsapp.number + '&text='+whatsapp.message" target="_blank">Clique aqui</a> para dúvidas, sugestões ou reservas.</p>
            </div>
        </a>
    `,
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

    @Input() phone: any;
    @Input() whatsapp: any;
    @Input() data: any;

    constructor(
        private documentService: DocumentService,
        private appDataService: AppDataService
    ) {}

    ngOnInit(): void {
        if (!this.phone) {
            const dataFooter: any = JSON.parse(this.documentService.getWindow()?.sessionStorage.getItem('footer')!);
            this.phone = dataFooter.phone;
        }

        let novoDiscagem = [];

        for (const phone of this.phone) {
            let numberTollFree = phone.substring(0, 4).includes('0800');

            let number;
            let type;
            let ddi;

            number = phone;

            if (!numberTollFree) {
                number = number.replace(/\D+/g, '').replace(/^0+/, '');
            }

            ddi = this.appDataService.hasNumberDdi(number);

            if (numberTollFree) {
                type = 'tollFree';
            } else if (number.length > (ddi ? 12 : 10)) {
                type = 'mobilePhone';
            } else {
                type = 'landlinePhone';
            }

            novoDiscagem.push({
                number,
                type,
                ddi
            })

            this.phone = novoDiscagem;
        }

        if (!this.whatsapp.number) {
            this.whatsapp.number = ''
        }
 
        if (this.whatsapp) {
            const whatsNewNumber = this.whatsapp.number.replace(/\D+/g, '').replace(/^0+/, '');
            const whatsVerification = whatsNewNumber.substring(0, 3).includes('55');

            this.whatsapp.number = whatsNewNumber;
            if (!whatsVerification && this.whatsapp.number.length <= 11) {
                const newWhats = `55${this.whatsapp.number}`;
                this.whatsapp.number = newWhats;
            }
        }
    }

}
