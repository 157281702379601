import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { SanitizeXssService } from '../../services/sanitize-xss.service';

@Component({
  selector: 'my-portal-dialog-forgot-password',
  templateUrl: './dialog-forgot-password.component.html',
  styleUrls: ['./dialog-forgot-password.component.scss']
})
export class DialogForgotPasswordComponent implements OnInit {
  inputValue: any;
  passwordForm: FormGroup;
  loading: boolean = false;
  successMessage: any;
  errorMessage: any;
  loginType: string = '';
  email: any;

  constructor(
    public dialogRef: MatDialogRef<DialogForgotPasswordComponent>,
    private formBuilder: FormBuilder,
    private xssSanitizerService: SanitizeXssService,
    private appData: AppDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.passwordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });

    this.loginType = this.data.loginType;
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(form: any): void {
    this.loading = true;
    this.handleForgotPassword(this.xssSanitizerService.sanitizeXSS(form.value.email));
    this.email = this.xssSanitizerService.sanitizeXSS(form.value.email);
  }

  handleForgotPassword(email: any): void {
    this.appData.postRestrictedForgotPassword(this.loginType, email).subscribe(
      (data: any) => {
        this.loading = false;
        this.successMessage = true;
        this.passwordForm.reset();
      },
      (error: any) => {
        this.loading = false;
        this.errorMessage = true;
      }
    );
  }
}
