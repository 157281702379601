import {Component, OnInit, Input} from '@angular/core';
import {AppDataService} from '../../services/app-data.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DocumentService} from '../../services/document.service';
import {InitializerService} from '../../services/initializer.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DefaultSnackBarComponent} from '../default-snack-bar/default-snack-bar.component';
import { SanitizeXssService } from '../../services/sanitize-xss.service';

@Component({
    selector: 'my-portal-webcheckin',
    templateUrl: './webcheckin.component.html',
    styleUrls: ['./webcheckin.component.scss'],
})
export class WebcheckinComponent implements OnInit {

    @Input() infoData: any;

    loading: any;
    checkinForm: FormGroup;
    data: any | null = this.documentService
        .getWindow()
        ?.sessionStorage.getItem('footer');
    checkinOut: any;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();

    constructor(
        private initializerService: InitializerService,
        private appData: AppDataService,
        private formBuilder: FormBuilder,
        private xssSanitizerService: SanitizeXssService,
        private documentService: DocumentService,
        private snackbar: MatSnackBar
    ) {
        this.checkinForm = this.formBuilder.group({
            reserve: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.data = JSON.parse(this.data);

        this.initializerService.currentData
            .subscribe((data: any) => {
                if (data) {
                    this.checkinOut = {
                        checkin: data.checkin,
                        checkout: data.checkout,
                    };
                }
            })
            .unsubscribe();
    }

    onSubmit(form: any): void {
        this.loading = true;

        this.appData
            .postWebCheckinPass(this.xssSanitizerService.sanitizeXSS(form.value.reserve), this.infoData.id)
            .subscribe(
                (reserve: any) => {
                    this.loading = false;
                    this.checkinForm.reset();
                    this.openSnackBar(
                        'E-mail enviado com sucesso!',
                        false
                    );
                },
                (exception: any) => {
                    this.loading = false;
                    this.checkinForm.reset();
                    this.openSnackBar(
                        exception.error.errors.message,
                        true
                    );
                }
            );
    }

    openSnackBar(msg: string, flag?: boolean | undefined): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: (flag) ? 'error-snack-bar' : 'success-snack-bar',
            duration: 5000
        });
    }
}
