import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SanitizeXssService {

  constructor() { }

  public sanitizeXSS(text: string): string {
    return text.replace(/(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script|style(\s*)=|(<\s*)meta|>|<(?!(\s*\/?script|\s*meta))/gi, '');
  }
}
