import { InitializerService } from './../../services/initializer.service';
import { DefaultSnackBarComponent } from './../default-snack-bar/default-snack-bar.component';
import { Component, Input, OnInit } from '@angular/core';
import { AppDataService } from '../../services/app-data.service';

import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { DocumentService } from '../../services/document.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SanitizeXssService } from '../../services/sanitize-xss.service';

@Component({
    selector: 'my-portal-testimony-form',
    templateUrl: './testimony-form.component.html',
    styleUrls: ['./testimony-form.component.scss'],
})
export class TestimonyFormComponent implements OnInit {
    @Input() formVisible: boolean = false;
    @Input() hotelsList: any;
    @Input() typeSearch: any;
    @Input() personId: any;

    testimonyForm: FormGroup;

    loading: boolean = false;
    hotelForm = new FormControl();
    disabledButton: boolean = false;
    disabledRecaptcha: boolean = false;
    getScreenWidth: boolean = this.initializerService.isMobileDevice();

    constructor(
        private initializerService: InitializerService,
        private appData: AppDataService,
        private xssSanitizerService: SanitizeXssService,
        private formBuilder: FormBuilder,
        private snackbar: MatSnackBar
    ) {
        this.testimonyForm = this.formBuilder.group({
            pessoa_id: [null],
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', Validators.required],
            testimony: ['', Validators.required]
        });
    }

    ngOnInit(): void {}

    onSubmit(): void {
        this.loading = true;
        const formData = this.testimonyForm.value;

        for (const keyForm of Object.keys(this.testimonyForm.value)) {
            if (keyForm !== 'pessoa_id') formData[keyForm] = this.xssSanitizerService.sanitizeXSS(formData[keyForm])
        }

        this.appData
            .postTestimonyForm(formData)
            .subscribe(
                (response: any) => {
                    if (response.data) {
                        this.loading = false;
                        this.openSnackBar(
                            'Depoimento enviado com sucesso!',
                            false
                        );
                    }
                (error: any) => {
                    this.loading = false;
                    this.openSnackBar(
                        'Erro ao enviar depoimento!',
                        true
                    );
                }
            });
    }

    privacyPolicy($event: any): void {
        this.disabledButton = $event;
    }

    recaptcha($event: any): void {
        this.disabledRecaptcha = $event;
    }

    openSnackBar(msg: string, flag?: boolean | undefined): void {
        this.snackbar.openFromComponent(DefaultSnackBarComponent, {
            data: {
                message: msg
            },
            horizontalPosition: 'end',
            verticalPosition: this.getScreenWidth ? 'top' : 'bottom',
            panelClass: (flag) ? 'error-snack-bar' : 'success-snack-bar',
            duration: 5000
        });
    }
}
