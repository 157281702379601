<!--<div class="row">-->
<!--    <ng-template #listGenericPages *ngFor="let package of packageList?.items" [ngTemplateOutlet]="listGenericPages">-->
<!--        <div class="col mb-4">-->
<!--            <figure [ngClass]="{'loading': load }" class="package">-->
<!--                <div *ngIf="package?.discount" class="discount">-->
<!--                    {{package?.discount}}-->
<!--                </div>-->
<!--                <img class="img-fluid" src="{{package?.image}}">-->
<!--            </figure>-->
<!--            <div class="content-list-package p-3 border border-top-0">-->
<!--                <h6 [ngClass]="{'loading': load }" class="mt-2">{{package.title}}</h6>-->
<!--                <div [ngClass]="{'my-2': load }">-->
<!--                    <p [innerHTML]="package.description" [ngClass]="{'loading': load }"></p>-->
<!--                </div>-->
<!--                <div *ngIf="!load" [ngClass]="{'no-border': !package.count}" class="price package">-->
<!--                    <div>-->
<!--                        <div class="old">De: {{ package.old_price | currency }}</div>-->
<!--                        <div class="total">Por: {{ package.price | currency }}</div>-->
<!--                    </div>-->
<!--                    <div *ngIf="package.max_parcels && package.max_parcels > 1" class="text-right">-->
<!--                        <div class="description">Parcelamos em até</div>-->
<!--                        <div class="total">{{package.max_parcels}}x sem juros</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <my-portal-counter [date]="package.final_date"></my-portal-counter>-->

<!--                <a [routerLink]="['/promocao', package.slug, package.id]">-->
<!--                    <button *ngIf="!load" [ngClass]="{'loading': load }">-->
<!--                        reservar agora-->
<!--                    </button>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-template>-->
<!--</div>-->

<!--<pre>{{packageList | json }}</pre>-->
<div *ngIf="packageList">
    <div class="row no-gutters packages mb-5" *ngFor="let package of packageList?.items; let i = index">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <figure [ngClass]="{'loading': load }" class="package">
                <div class="discount" *ngIf="package.discount">{{ package.discount }}%</div>
                <img
                    src="{{imgServer + 'width=' + 555 + imgServerUrlEnd +  package.image}}"
                    class="img-fluid">
            </figure>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 content-package">
            <div class="content-box">
                <h6 [ngClass]="{'loading': load }">{{package.title}}</h6>
                <span>De {{ package.lowest_rate.checkin | date: 'dd.MM.yyyy' }} à {{ package.lowest_rate.checkout | date: 'dd.MM.yyyy' }}</span>
                <div [ngClass]="{'my-2': load }">
                    <p [ngClass]="{'loading': load }" [innerHTML]="package.description"></p>
                </div>
            </div>
            <!--        *ngIf="package.count" max_parcels-->
            <my-portal-counter [type]="true" *ngIf="package.count" [date]="package.final_date"></my-portal-counter>
            <div class="footer">
                <div class="prices" *ngIf="!load">
                    <div class="lower-price" *ngIf="package.discount">De: {{ package.lowest_rate.old_price | currency }}</div>
                    <div class="price">{{ package.discount !== 0 ? 'Por:' : 'A partir de:'}} <br *ngIf="package.discount === 0"> {{ package.lowest_rate.price | currency }}</div>
                </div>

                <div class="payment" *ngIf="package.max_parcels && package.max_parcels > 1">
                    parcelamos em até
                    <div>{{package.max_parcels}}x no cartão de crédito</div>
                </div>

                <a [routerLink]="['/promocao', package.slug, package.id]">
                    <button>
                        reservar agora
                    </button>
                </a>
            </div>

        </div>
    </div>
</div>


