import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Input } from '@angular/core';

@Component({
  selector: 'my-portal-dialog-cancellation',
  templateUrl: './dialog-cancellation.component.html',
  styleUrls: ['./dialog-cancellation.component.scss']
})

export class DialogCancellationComponent implements OnInit {
  
  dataReserve: any;
  loading: boolean = false;
  userType: any;

  constructor(
    public dialogRef: MatDialogRef<DialogCancellationComponent>,
    private appData: AppDataService,
    @Inject(MAT_DIALOG_DATA) public data: DialogCancellationComponent | any
  ) { }

  ngOnInit(): void {
    this.dataReserve = this.data.reserve;
    this.userType = this.data.userType;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleCancellation(): void {
    this.loading = true;
    this.appData.postRestrictedAreaCancelReserve(this.dataReserve.id,this.userType).subscribe(
      (response: any) => {
        this.loading = false;
        this.dialogRef.close('cancelado');
      },
      (error) => {
        this.dialogRef.close();
      }
    );
  }


}
