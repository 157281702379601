<div *ngIf="data">
    <ng-container *ngFor="let module of data.modules" [ngSwitch]="module?.module_id">
        <ng-template ngSwitchCase="3">
            <my-portal-testimonies [moduleData]="module" [typeSlide]="true" [formVisible]="false"></my-portal-testimonies>
        </ng-template>
        <ng-template ngSwitchCase="23">
            <my-portal-testimonies [moduleData]="module" [typeSlide]="true" [formVisible]="false"></my-portal-testimonies>
        </ng-template>
        <ng-template ngSwitchCase="6">
            <my-portal-about [moduleData]="module"></my-portal-about>
        </ng-template>
        <ng-template ngSwitchCase="4">
            <my-portal-webcheckin [infoData]="data"></my-portal-webcheckin>
       </ng-template>
        <ng-template ngSwitchCase="5">
             <my-portal-accommodations [moduleData]="module" [establishmentType]="data?.type"></my-portal-accommodations>
        </ng-template>
        <ng-template ngSwitchCase="12">
            <my-portal-packages [moduleData]="module"></my-portal-packages>
        </ng-template>
        <ng-template ngSwitchCase="14">
            <my-portal-banners [moduleData]="module"></my-portal-banners>
        </ng-template>
        <ng-template ngSwitchCase="15">
            <my-portal-banners [moduleData]="module"></my-portal-banners>
        </ng-template>
        <ng-template ngSwitchCase="16">
            <my-portal-banners [moduleData]="module"></my-portal-banners>
        </ng-template>
        <ng-template ngSwitchCase="17">
            <my-portal-banners [moduleData]="module"></my-portal-banners>
        </ng-template>
        <ng-template ngSwitchCase="18" *ngIf="!isMobile">
            <my-portal-banners [moduleData]="module"></my-portal-banners>
        </ng-template>
        <ng-template ngSwitchCase="25" *ngIf="isMobile">
            <my-portal-banners [moduleData]="module"></my-portal-banners>
        </ng-template>
        <ng-template ngSwitchCase="19">
            <my-portal-gallery [limitImages]="true" [moduleData]="module"></my-portal-gallery>
        </ng-template>
        <ng-template ngSwitchCase="20">
            <my-portal-video-player [moduleData]="module" [type]="{type: 'tabs'}"></my-portal-video-player>
        </ng-template>
        <ng-template ngSwitchCase="21">
            <my-portal-cashback [moduleData]="module"></my-portal-cashback>
        </ng-template>
        <ng-template ngSwitchCase="26">
            <my-portal-hotels [moduleData]="module"></my-portal-hotels>
        </ng-template>
        <ng-template ngSwitchCase="27">
            <my-portal-destiny [moduleData]="module"></my-portal-destiny>
        </ng-template>
        <ng-template ngSwitchCase="29">
            <my-portal-tours [moduleData]="module"></my-portal-tours>
        </ng-template>
        <ng-template ngSwitchCase="30">
            <my-portal-transfers [moduleData]="module"></my-portal-transfers>
        </ng-template>
        <ng-template ngSwitchCase="31">
            <my-portal-tickets [moduleData]="module"></my-portal-tickets>
        </ng-template>
        <ng-template ngSwitchCase="32">
            <my-portal-certificates></my-portal-certificates>
        </ng-template>
        <ng-template ngSwitchCase="33">
            <my-portal-benefits [search]="moduleData"></my-portal-benefits>
        </ng-template>
        <ng-template [ngSwitchCase]="null">
            <my-portal-news *ngIf="module?.type === 1"  [moduleData]="module"></my-portal-news>
            <my-portal-packages *ngIf="module?.type === 2" [moduleData]="module" [landingPageId]="module?.landing_page_categoria_id"></my-portal-packages>
            <my-portal-pages *ngIf="module?.type === 3" [moduleData]="module" ></my-portal-pages>
        </ng-template>
    </ng-container>
</div>

