import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppDataService } from 'projects/new-portal/src/app/services/app-data.service';
import { SanitizeXssService } from 'projects/new-portal/src/app/services/sanitize-xss.service';

@Component({
    selector: 'my-portal-dialog-voucher',
    templateUrl: './dialog-voucher.component.html',
    styleUrls: ['./dialog-voucher.component.scss'],
})
export class DialogVoucherComponent implements OnInit {
    inputValue: any;
    couponForm: FormGroup;
    loading: boolean = false;
    dataCoupon: any;
    errorMessage: any;

    constructor(
        public dialogRef: MatDialogRef<DialogVoucherComponent>,
        private formBuilder: FormBuilder,
        private xssSanitizerService: SanitizeXssService,
        private appData: AppDataService
    ) {
        this.couponForm = this.formBuilder.group({
            coupon: ['', Validators.required],
        });
    }

    ngOnInit(): void {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(form: any): void {
        this.loading = true;
        this.handleGenerateVoucher(this.xssSanitizerService.sanitizeXSS(form.value.coupon));
    }

    handleGenerateVoucher(value: string): void {
        this.appData.postRestrictedAreaGenerateVoucher(value).subscribe(
            (response: any) => {
                this.loading = false;
                this.dataCoupon = response.data;
                this.couponForm.reset();
            },
            (error) => {
                this.loading = false;
                this.errorMessage = error.error.failure.message;
            }
        );
    }
}
