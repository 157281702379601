<my-portal-loading *ngIf="load && !isDisableSite"></my-portal-loading>
<mat-sidenav-container [attr.fullscreen]="sidenav.opened ? true : null" *ngIf="!load && !isDisableSite" autosize>
    <mat-sidenav #sidenav (closed)="onClose()">
        <mat-nav-list>
            <ng-template #listMenuSidnav [ngTemplateOutlet]="listMenuSidnav"
                         *ngFor="let menu of data.menus; let ind = index">
                <div class="dropdown">
<!--                    <a [routerLink]="menu.submenu.length > 0 ? null : menu.url" mat-list-item-->
<!--                       (click)="menu.submenu.length > 0 ? openSubmenu(ind) : sidenav.toggle()">-->
<!--                        {{ menu.title }}-->
<!--                    </a>-->

                    <a [routerLink]="menu.submenu.length > 0 ? null : menu.url" *ngIf="menu.target === '_self'"
                       (click)="menu.submenu.length > 0 ? openSubmenu(ind) : sidenav.toggle()" mat-list-item>
                        {{ menu.title }}
                    </a>

                    <a *ngIf="menu.target === '_blank'" [href]="menu.url" [target]="menu.target" mat-list-item>
                        {{ menu.title }}
                    </a>
                    <div class="dropdown-content" *ngIf="submenuExpanded(ind)">
                        <a *ngFor="let sub of menu.submenu" mat-list-item [href]="sub.url" [target]="sub.target"
                           class="dropdown-item">{{sub.title}}</a>
                    </div>
                    <mat-icon *ngIf="menu.submenu.length > 0">arrow_drop_down</mat-icon>
                </div>
            </ng-template>
            <div class="dropdown">
                <a (click)="sidenav.toggle()" [routerLink]="['/contato']" mat-list-item>Contato</a>
            </div>
            <div class="dropdown" *ngIf="data.type !== 6">
                <a (click)="openDialog(); sidenav.toggle()" mat-list-item>Minha conta</a>
            </div>

            <div class="dropdown" *ngIf="data.only_motor">
                <a [href]="data.url_site" target="_blank" mat-list-item>Voltar ao site</a>
            </div>

            <div class="container-translate mt-2" *ngIf="isMobile">
                <my-portal-translate [whiteBackground]="true" [dataHeader]="data"></my-portal-translate>
            </div>
        </mat-nav-list>

    </mat-sidenav>
    <mat-sidenav-content>
        <div>
            <my-portal-header></my-portal-header>
            <main class="container" id="main">
                <router-outlet></router-outlet>
            </main>
            <my-portal-footer [info]="data"></my-portal-footer>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="isDisableSite" class="disabled-site d-flex justify-content-center align-items-center">
    <div class="container">
        <div class="alert alert-danger text-center text-uppercase" role="alert">
            Serviço temporariamente fora do ar
        </div>
    </div>
</div>
