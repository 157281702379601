import {Component, Input, OnInit} from '@angular/core';
import {InitializerService} from '../../../services/initializer.service';
import {DocumentService} from '../../../services/document.service';
import {SeoService} from '../../../services/seo.service';

@Component({
    selector: 'my-portal-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

    data: any;
    typeSearch: any = this.documentService.getWindow()?.sessionStorage.getItem('typeSearch');
    @Input() moduleData: any;
    isMobile: boolean = this.initializerService.isMobileDevice();

    constructor(private initializerService: InitializerService,
                private documentService: DocumentService,
                private seoService: SeoService) {
    }

    ngOnInit(): void {
        this.initializerService.currentData.subscribe((response) => {
            this.data = response;
            this.seoService.updateSeo(this.data);
        }).unsubscribe();
    }

}
