import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateDatesDiff'
})
export class CalculateDatesDiffPipe implements PipeTransform {

    transform(firstDate: string | Date, secondDate: string | Date): number {
        if (!firstDate || !secondDate) return 0;

        const firstDateInstance = new Date(firstDate);
        const secondDateInstace = new Date(secondDate);

        const diffTime = secondDateInstace.getTime() - firstDateInstance.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
      }

}
